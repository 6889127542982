<template>
  <div class="composite-small-calendar">
    <div class="header">
      <h3>{{ title }}</h3>
      <div class="header-actions">
        <CommonIconButton @click="prev" icon="ArrowLeft" />
        <CommonIconButton @click="next" icon="ArrowRight" />
      </div>
    </div>
    <FullCalendar ref="calendar" :options="options" class="small-calendar">
      <template #eventContent="{ event }">
        <div
          class="small-calendar-event"
          :class="{
            'small-calendar-success': event.extendedProps.status == 0,
            'small-calendar-warning': event.extendedProps.status == 1,
            'small-calendar-danger': event.extendedProps.status == 2,
            'small-calendar-holyday': event.extendedProps.isHolyday
          }"
          :style="mode ? getDensityStyle(event) : undefined"
        >
          <div class="small-calendar-info" v-if="event.extendedProps.count">
            {{ event.extendedProps.count }} int.
          </div>
          &nbsp;
        </div>
      </template>
    </FullCalendar>
    <CommonSwitch v-model="mode" text="Vue Statut" activeText="Vue Densité" />
  </div>
</template>

<script setup lang="ts">
import { type Calendar, type CalendarOptions, type EventInput } from '@fullcalendar/core/index.js'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { type DateClickArg } from '@fullcalendar/interaction'
import { getEventDensity, type SmallCalendarEvent } from '@/services/service_calendar'
import { onBeforeMount, onMounted, reactive, ref, watch, type StyleValue, nextTick } from 'vue'
import CommonIconButton from '../common/CommonIconButton.vue'
import CommonSwitch from '../common/CommonSwitch.vue'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import { useCalendarStore } from '@/stores/gae/store_calendar'

const props = defineProps<{
  current?: Date
}>()

const emits = defineEmits(['update:current'])
const calendar = ref<InstanceType<typeof FullCalendar>>()
const calendar_api = ref<Calendar | undefined>()
const title = ref()
const mode = ref(false)
const intervention_store = useInterventionStore()
const calendar_store = useCalendarStore()

const current_day_event = ref<EventInput>({
  date: calendar_store.getCurrentDay,
  className: 'current-event'
})

const options: CalendarOptions = reactive({
  plugins: [dayGridPlugin, interactionPlugin],
  initialView: 'dayGridMonth',
  headerToolbar: false,
  dayHeaderFormat: { weekday: 'narrow' },
  firstDay: 1,
  locale: 'fr',
  eventBorderColor: '#fff',
  viewClassNames: 'fc-small',
  height: 236,
  weekNumbers: true,
  dayCellClassNames: 'fc-small-events',
  dayHeaderClassNames: 'fc-small-header',
  weekNumberClassNames: 'fc-small-wn',
  eventClassNames: 'fc-small-event',
  weekNumberFormat: { week: 'numeric' },
  events: [],
  dateClick: dateClick,
  eventClick: () => false
})

onBeforeMount(() => {
  watch(
    () => calendar_store.getSmallEvents,
    (events) => {
      options.events = [current_day_event.value, ...events, ...calendar_store.getHolydaysEvents]
    }
  )

  watch(
    () => calendar_store.getCurrentDay,
    (day) => {
      current_day_event.value.date = day
      calendar_api.value?.gotoDate(day)
    }
  )
})

onMounted(() => {
  if (calendar.value) {
    calendar_api.value = calendar.value.getApi()
    intervention_store.fetchMonth(calendar_api.value.getDate()).then(() => {
      calendar_store.updateHighestCount()
    })
    options.events = [
      current_day_event.value,
      ...calendar_store.getSmallEvents,
      ...calendar_store.getHolydaysEvents
    ]
    title.value = calendar_api.value.view.title

    // Rustine fix strange size bug on mount?
    nextTick(() => {
      calendar_api.value?.next()
      calendar_api.value?.prev()
    })
  }
})

function next() {
  if (calendar_api.value) {
    calendar_api.value.next()
    intervention_store.fetchMonth(calendar_api.value.getDate()).then(() => {
      calendar_store.updateHighestCount()
      if (calendar_api.value?.getDate()) {
        calendar_store.setCurrentDay(calendar_api.value?.getDate())
      }
    })

    title.value = calendar_api.value.view.title
  }
}

function prev() {
  if (calendar_api.value) {
    calendar_api.value.prev()
    intervention_store.fetchMonth(calendar_api.value.getDate()).then(() => {
      calendar_store.updateHighestCount()
      if (calendar_api.value?.getDate()) {
        calendar_store.setCurrentDay(calendar_api.value?.getDate())
      }
    })

    title.value = calendar_api.value.view.title
  }
}

function dateClick(event: DateClickArg) {
  calendar_store.setCurrentDay(event.date)
  emits('update:current', event.date)
}

function getDensityStyle(event: SmallCalendarEvent): StyleValue {
  const highest = calendar_store.getHighestCount
  const count = event.extendedProps.count
  return {
    backgroundColor: getEventDensity(count, highest)
  }
}
</script>

<style scoped>
/* src/assets/small-calendar.scss */
.small-calendar {
  margin-bottom: 20px;
}

.composite-small-calendar {
  max-width: 310px;
  margin-bottom: 30px;
}

.header {
  margin-bottom: 20px;
  min-width: 290px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-actions {
  display: flex;
  gap: 10px;
}

.content {
  display: flex;
}

.small-calendar-holyday::after {
  position: absolute;
  right: 0;
  content: '';
  background-color: var(--vc-primary);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
</style>
