<template>
  <div class="layout-sideimage">
    <div class="layout-sideimage-content">
      <div class="mb-40">
        <slot />
      </div>
      <p>
        Besoin d’aide ?<br v-if="v_mobile" />
        Appelez-nous au <a href="tel:014295671">01 42 97 56 71</a>
      </p>
    </div>
    <div v-if="!v_mobile" class="layout-sideimage-image">
      <img :src="image" :alt="alt" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

defineProps<{
  image: string
  alt?: string
}>()

const breakpoint = useBreakpoints(breakpointsTailwind)
const v_mobile = breakpoint.smallerOrEqual('sm')
</script>

<style scoped>
.layout-sideimage {
  display: flex;
  height: 100vh;
}

.layout-sideimage-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  padding: 30px;
}

.layout-sideimage-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.layout-sideimage-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 640px) {
  .layout-sideimage-content {
    max-width: 400px;
    padding: 30px 170px 30px 50px;
    overflow-y: auto;
  }
}

@media screen and (min-width: 1536px) {
  .layout-sideimage-content {
    max-width: unset;
    min-width: 30%;
    padding: 30px 170px 30px 50px;
  }
}
</style>
