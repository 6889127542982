<template>
  <div class="layout-sidebar" :class="{ tablet: v_tablet, mobile: v_mobile }">
    <div class="layout-sidebar__left" :class="{ closed: !isOpen }">
      <div class="inner-left">
        <div v-if="v_mobile" class="mobile-header">
          <img :src="LogoGAE" alt="logo-gae-conseil" />
          <AlignRight v-if="v_mobile && !isOpen" @click="isOpen = true" :size="40" />
          <X v-if="isOpen && v_mobile" @click="isOpen = false" :size="40" />
        </div>
        <div
          @click="isOpen = false"
          class="sidebar-content"
          :class="{ 'mobile-open': !v_mobile || isOpen }"
        >
          <img
            v-if="v_desktop"
            class="layout-sidebar-image"
            :src="LogoGAE"
            alt="logo-gae-conseil"
          />
          <slot name="left" />
        </div>
        <div v-if="v_desktop" class="layout-sidebar-footer">
          <div class="one-drive-links" v-if="security.isConsultant">
            <div>
              <CommonIcon name="Link" :stroke-width="1.5" :size="16" />
              <a
                href="https://gaeconseil2.sharepoint.com/:f:/s/CONSULTANTS/Enk4L6yFOVZJrsSr46IhoYgB8i-fQ8Rpa4osnktlCZxRFw"
                target="_blank"
              >
                OneDrive commun
              </a>
            </div>
            <div v-if="user_store.getConsultant.onedrive">
              <CommonIcon name="Link" :stroke-width="1.5" :size="16" />
              <a :href="user_store.getConsultant.onedrive" target="_blank"> OneDrive personnel </a>
            </div>
          </div>
          <p>Besoin d’aide ?<br />Appelez-nous au <a href="tel:0142975671">01 42 97 56 71</a></p>
          <p v-if="security.user">
            {{ security.user?.lastname?.toUpperCase() }} {{ security.user?.firstname }}
          </p>
          <a href="#" @click="security.logout" class="logout-button">Se déconnecter</a>
        </div>
      </div>
    </div>
    <div class="layout-sidebar__right">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
import LogoGAE from '@/assets/images/logo-gae-2.png'
import { useSecurityStore } from '@/stores/gae/store_security'
import { useUserStore } from '@/stores/gae/store_user'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { AlignRight, X } from 'lucide-vue-next'
import { onMounted, ref } from 'vue'
import CommonIcon from '../common/CommonIcon.vue'

const breakpoint = useBreakpoints(breakpointsTailwind)
const v_desktop = breakpoint.greater('lg')
const v_tablet = breakpoint.between('sm', 'lg')
const v_mobile = breakpoint.smallerOrEqual('sm')
const user_store = useUserStore()

const security = useSecurityStore()
const isOpen = ref(false)

onMounted(() => {
  const id = security.user?.id
  if (!id || !security.isConsultant) return
  user_store.get(id)
})
</script>

<style scoped lang="scss">
.layout-sidebar {
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
  display: grid;
  grid-template-columns: 300px 1fr;
}

.inner-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--vc-dark);
  color: var(--vc-white);
  padding-top: 30px;
  padding-bottom: 60px;
  height: 100vh;
  width: 300px;
  box-sizing: border-box;
}

.mobile .inner-left {
  width: auto;
}

.sidebar-content > img {
  padding-left: 25px;
  padding-right: 25px;
}

.tablet {
  grid-template-columns: 80px 1fr;
}

.tablet > .layout-sidebar__left > .inner-left {
  width: 80px;
  padding: 0;
}

.mobile {
  display: inline;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.mobile > .layout-sidebar__left > .inner-left {
  padding: 0;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
}

.mobile > .layout-sidebar__left > .inner-left > .sidebar-content {
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 0px;
  overflow: hidden;
  width: 100%;
  background-color: var(--vc-dark);
  position: absolute;
  z-index: 99;
  top: 60px;
  left: 0;
}

.mobile-header {
  font-weight: 500;
  font-size: 32px;
  font-family: 'Jost', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.mobile-header > img {
  width: 100px;
  height: auto;
}

.mobile-open {
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 300px !important;
}

.layout-sidebar-footer {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.75);
  padding-left: 30px;
  padding-right: 30px;
}

.layout-sidebar-footer > p {
  color: rgba(255, 255, 255, 0.75);
}
.layout-sidebar-footer > p:first-of-type {
  margin-bottom: 30px;
}

.layout-sidebar-footer > p:last-of-type,
.layout-sidebar-footer > a:last-of-type {
  color: var(--vc-white);
  font-size: 19px;
}

.layout-sidebar-image {
  margin-bottom: 60px;
  height: auto;
  max-width: 250px;
}

.layout-sidebar__right {
  overflow: scroll;
  min-height: 100vh;
}

.mobile > .layout-sidebar__right {
  overflow: unset;
}

.one-drive-links {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.one-drive-links > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.one-drive-links > div > a {
  color: var(--vc-white);
  font-size: 16px;
}

.one-drive-links > div > a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 640px) {
  .inner-left {
    position: fixed;
  }
}

@media screen and (max-height: 680px) {
  .layout-sidebar-image {
    max-width: 180px;
    margin-bottom: 30px;
  }
}
</style>
