<template>
  <div>
    <label v-if="label" class="label" :for="id">{{ label }} :</label>
    <CommonLoader v-if="fetching" />
    <div class="files">
      <template v-for="(file, index) in modelValue" :key="index">
        <CommonChip class="file" filled color="success" :size="readonly ? 'medium' : 'small'">
          <a v-if="file.downloadUrl" :href="`/api/${file.downloadUrl}`">
            {{ clearFilename(file.filename) }}
          </a>
          <p v-else>{{ clearFilename(file.filename) }}</p>
          <CommonIcon
            v-if="!readonly"
            class="input-file-delete"
            name="X"
            color="white"
            :size="16"
            @click="removeFile(file.id)"
          />
        </CommonChip>
      </template>
    </div>
    <div v-if="!readonly" class="input-file">
      <div class="file-area">{{ clearFilename(selected_file) }}</div>
      <input :id="id" type="file" @change="processFile" :accept="accept" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFile } from '@/stores/gae/store_file'
import type { MediaObjectDTO } from 'types/api/file'
import CommonIcon from '../CommonIcon.vue'
import { ref } from 'vue'
import CommonChip from '../CommonChip.vue'
import CommonLoader from '../CommonLoader.vue'
import { handleErrorNotification } from '@/services/service_api'

const file = useFile()
const selected_file = ref('')
const fetching = ref(false)

const props = defineProps<{
  id: string
  accept: string
  readonly?: boolean
  label?: string
  modelValue?: MediaObjectDTO[]
}>()
const emits = defineEmits<{
  (e: 'update:modelValue', value: MediaObjectDTO[]): void
  (e: 'on:upload', value: MediaObjectDTO): void
  (e: 'on:remove', value: number): void
}>()

function processFile(event: Event) {
  let formFile = (event.target as HTMLInputElement).files
  if (!formFile) return

  fetching.value = true
  file
    .uploadFile(formFile[0])
    .then((res) => {
      const files = props.modelValue ?? []

      const media_object = {
        id: res.data.id ?? 0,
        filename: res.data.filename ?? '',
        mimeType: res.data.mimeType ?? 'Unknown Type',
        size: res.data.size ?? 0,
        downloadUrl: ''
      }

      files.push(media_object)
      selected_file.value = res.data.filename ?? ''
      emits('update:modelValue', files)
      emits('on:upload', media_object)
    })
    .catch(handleErrorNotification)
    .finally(() => {
      fetching.value = false
    })
}

defineExpose({ addFile })
function addFile(file: MediaObjectDTO) {
  const files = props.modelValue ?? []
  files.push(file)
  emits('update:modelValue', files)
}

// Match last hyphen and all character after it, stop when a dot is encountered
function clearFilename(filename: string) {
  const clearFilename = filename.replace(/-[^-.]*(?=\.[^.]*$)/, '')
  return clearFilename
}

function removeFile(id: number) {
  file.deleteFile(id)
  const files = props.modelValue ?? []
  const index = files.findIndex((item) => item.id === id)
  files.splice(index, 1)

  emits('update:modelValue', files)
  emits('on:remove', id)
}
</script>

<style scoped lang="scss">
input[type='file']::file-selector-button {
  background: var(--vc-light);
  color: var(--vcrgb-dark);
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  padding: 5px 20px;
  height: 30px;
  font-weight: 400;
  font-size: 16px;
  color: var(--vc-dark);
  cursor: pointer;
}

.input-file > input[type='file'] {
  color: transparent;
}

.input-file {
  position: relative;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 10px;
}

.input-file > .file-area {
  min-width: 340px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  color: var(--vc-dark);
  font-size: 14px;
  height: 30px;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.files {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.file {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file > p {
  color: white;
}

.file > a {
  color: white;
  text-decoration: underline;
}

.label {
  display: block;
  margin-bottom: 15px;
}

.input-file-delete {
  cursor: pointer;
}
</style>
