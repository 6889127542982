<template>
  <LayoutModalMessage ref="modal_confirm" v-model="confirm_open" @confirm="remove" />
  <LayoutModal :can-close="!confirm_open" @on-closed="() => (edit = false)" ref="modal">
    <LayoutModalContent class="modal-content">
      <div class="header">
        <CommonReturn @click="() => (edit ? (edit = false) : modal?.close())" text="Retour" />
        <div class="actions" v-if="security.isAdmin">
          <CommonIconButton
            v-if="!edit"
            color="dark"
            hover="Editer"
            squared
            icon="FileEdit"
            @click="load"
          />
          <CommonIconButton
            color="danger"
            hover="Supprimer"
            squared
            icon="Ban"
            @click="() => modal_confirm?.open()"
          />
        </div>
      </div>
      <div class="name">
        <h2>{{ consultant.acronym }} - {{ consultant.firstname }} {{ consultant.lastname }}</h2>
        <RouterLink :to="`/extranet/availabilities/${consultant.id}`" v-if="security.isAdmin">
          <CommonButton filled icon="CalendarDays" @click="() => modal?.close()"
            >Voir le planning</CommonButton
          >
        </RouterLink>
      </div>
      <div
        style="width: 100%; display: flex; justify-content: end; margin-bottom: 16px"
        v-if="security.isAdmin"
      >
        <RouterLink :to="`/extranet/billing/${consultant.id}`">
          <CommonButton filled icon="Wallet">Facturations</CommonButton>
        </RouterLink>
      </div>
      <div v-if="edit">
        <FormConsultant ref="form" class="mb-40" />
        <CommonButton
          :loading="user_store.fetching"
          class="submit mb-40"
          filled
          icon="Save"
          @click="submit"
        >
          Enregistrer les modifications
        </CommonButton>
      </div>
      <div v-else>
        <LayoutTabs :icons="['Contact', 'Package', 'ListOrdered', 'AreaChart']">
          <template #détails v-if="security.isAdmin">
            <div class="categories" v-if="consultant.skills.length > 0">
              <CommonChip v-if="consultant.is_expert_patient" size="large" filled color="primary"
                >Patient Expert</CommonChip
              >
              <CommonChip
                v-for="category of getSkillCategories(consultant.skills)"
                :key="category"
                size="large"
                transparent
                color="dark"
                >{{ category }}</CommonChip
              >
            </div>
            <div class="informations">
              <LabelEmail :email="consultant.email" :size="v_mobile ? 'small' : 'medium'" />
              <LabelLocalization
                v-if="consultant.address || consultant.city || consultant.postcode"
                :size="v_mobile ? 'small' : 'medium'"
                :localization="
                  [consultant.address, consultant.city, consultant.postcode].join(', ')
                "
              />
              <LabelAvailability
                :size="v_mobile ? 'small' : 'medium'"
                :day="consultant.availability.includes('day')"
                :night="consultant.availability.includes('night')"
                :weekend="consultant.availability.includes('weekend')"
              />
              <LabelPhone
                v-if="consultant.phone"
                :phone="consultant.phone"
                :size="v_mobile ? 'small' : 'medium'"
              />
              <LabelText
                icon="Briefcase"
                v-if="consultant.job"
                :text="consultant.job"
                :size="v_mobile ? 'small' : 'medium'"
              />
            </div>
            <CompositeZones
              v-if="consultant.consultantDepartements"
              ref="zones_component"
              readonly
              v-model="zones"
            />
          </template>
          <template #envoi_de_matériel v-if="security.isAdmin || security.isAdminReadonly">
            <CompositeMaterialTransit :consultant="consultant" />
          </template>
          <template #inventaire v-if="security.isAdmin || security.isAdminReadonly">
            <CompositeMaterial :consultant="consultant" />
          </template>
          <template #statistiques v-if="security.isAdmin">
            <CompositeConsultantStat :consultant="consultant" />
          </template>
        </LayoutTabs>
      </div>
    </LayoutModalContent>
  </LayoutModal>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import CommonChip from '@/components/common/CommonChip.vue'
import CommonIconButton from '@/components/common/CommonIconButton.vue'
import CommonReturn from '@/components/common/CommonReturn.vue'
import CompositeConsultantStat from '../CompositeConsultantStat.vue'
import LayoutModal from '@/components/layout/LayoutModal.vue'
import LayoutModalContent from '@/components/layout/LayoutModalContent.vue'
import type { APIConsultantPATCHRequest, Consultant } from 'types/api/consultant'
import { nextTick, ref } from 'vue'
import LabelAvailability from '../label/LabelAvailability.vue'
import LabelEmail from '../label/LabelEmail.vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import FormConsultant from '../forms/FormConsultant.vue'
import { useUserStore } from '@/stores/gae/store_user'
import { useSecurityStore } from '@/stores/gae/store_security'
import LayoutModalMessage from '@/components/layout/LayoutModalMessage.vue'
import { getSkillCategories } from '@/services/service_intervention'
import LabelLocalization from '../label/LabelLocalization.vue'
import LabelPhone from '../label/LabelPhone.vue'
import LabelText from '../label/LabelText.vue'
import { contract } from '@/services/service_consultant'
import { useToast } from 'vue-toast-notification'
import CompositeZones, { type FormInputLayout } from '../CompositeZones.vue'
import LayoutTabs from '@/components/layout/LayoutTabs.vue'
import CompositeMaterial from '../CompositeMaterial.vue'
import CompositeMaterialTransit from '../CompositeMaterialTransit.vue'

const props = defineProps<{
  consultant: Consultant
}>()

const user_store = useUserStore()
const modal = ref<InstanceType<typeof LayoutModal>>()
const modal_confirm = ref<InstanceType<typeof LayoutModalMessage>>()
const breakpoint = useBreakpoints(breakpointsTailwind)
const v_mobile = breakpoint.smallerOrEqual('sm')
const edit = ref(false)
const form = ref<InstanceType<typeof FormConsultant>>()
const confirm_open = ref(false)
const zones = ref(get_zones())
const zones_component = ref<InstanceType<typeof CompositeZones>>()
const security = useSecurityStore()

function get_zones(): FormInputLayout {
  return {
    '1': props.consultant.consultantDepartements[1]?.join(',') ?? '',
    '2': props.consultant.consultantDepartements[2]?.join(',') ?? '',
    '3': props.consultant.consultantDepartements[3]?.join(',') ?? '',
    '4': props.consultant.consultantDepartements[4]?.join(',') ?? '',
    '5': props.consultant.consultantDepartements[5]?.join(',') ?? ''
  }
}

function load() {
  edit.value = true
  nextTick(() => {
    if (form.value) {
      form.value.load(props.consultant)
    }
  })
}

function open() {
  if (modal.value) {
    modal.value.open()
  }
}

async function submit() {
  if (form.value) {
    const validation = await form.value.getValidation()
    if (!validation) {
      const toast = useToast()
      toast.error('Certains champs sont invalides.')
      return
    }

    const contract = getContract()
    if (contract) {
      try {
        await user_store.patch(props.consultant.id, contract)
      } catch (e) {
        return
      }

      edit.value = false
      await nextTick()
      zones_component.value?.update(get_zones())
    }
  }
}

function remove() {
  user_store.delete(props.consultant.id).then(() => {
    modal.value?.close(true)
  })
}

function getContract(): APIConsultantPATCHRequest | undefined {
  if (form.value) {
    const data = form.value.getData()
    return contract(data)
  }
}

defineExpose({ open })
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.actions {
  display: flex;
  gap: 10px;
}

.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.modal-content {
  margin-top: 46px;
}

.categories {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.informations {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.submit {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .name {
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }

  .informations {
    gap: 15px;
  }
}
</style>
