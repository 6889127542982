import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/home/ViewHomeLogin.vue'
import PasswordForgotView from '../views/home/ViewHomePasswordForgot.vue'
import EmailConfirmView from '../views/home/ViewHomeEmailConfirm.vue'
import PasswordResetView from '../views/home/ViewHomePasswordReset.vue'

import Extranet from '@/views/ExtranetView.vue'
import ExtranetHome from '../views/extranet/ViewExtranetHome.vue'
import ExtranetUsers from '../views/extranet/ViewExtranetUsers.vue'
import ExtranetTools from '../views/extranet/ViewExtranetTools.vue'
import ExtranetAccount from '@/views/extranet/ViewExtranetAccount.vue'
import ExtranetAdminDashboard from '@/views/extranet/ViewExtranetAdminDashboard.vue'
import ExtranetDashboard from '@/views/extranet/ViewExtranetDashboard.vue'
import ExtranetAvailabilities from '@/views/extranet/ViewExtranetAvailabilities.vue'
import ExtranetMissing from '@/views/extranet/ViewExtranetMissing.vue'

import { useSecurityStore } from '@/stores/gae/store_security'
import ExtranetBilling from '@/views/extranet/ViewExtranetBilling.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      beforeEnter: () => {
        const security = useSecurityStore()
        if (security.is_authenticated) return '/extranet'
      },
      children: [
        {
          path: '',
          name: 'login',
          component: LoginView
        },
        {
          path: 'password-forgot',
          name: 'password-forgot',
          component: PasswordForgotView
        },
        {
          path: 'confirm-email',
          name: 'confirm-email',
          component: EmailConfirmView
        },
        {
          path: 'password-reset/:token',
          name: 'password-reset',
          component: PasswordResetView,
          beforeEnter: (to) => {
            if (!to.params.token) {
              return '/'
            }
          }
        }
      ]
    },
    {
      path: '/extranet',
      name: 'extranet',
      component: Extranet,
      beforeEnter: async () => {
        const security = useSecurityStore()
        await security.fetchMe();

        if (!security.is_authenticated) {
          return '/'
        }
      },
      children: [
        {
          path: 'dashboard',
          name: 'admin-dashboard',
          component: ExtranetAdminDashboard,
          beforeEnter: async () => {
            const security = useSecurityStore()
            if (!security.isAdmin) return '/extranet'
          }
        },
        {
          path: 'calendar',
          name: 'extranet-calendar',
          component: ExtranetHome,
          beforeEnter: async () => {
            const security = useSecurityStore()
            if (!security.isAdmin && !security.isAdminReadonly) return '/extranet'
          }
        },
        {
          path: 'availabilities',
          name: 'extranet-availabilities',
          component: ExtranetAvailabilities
        },
        {
          path: 'availabilities/:id',
          name: 'extranet-consultant-availabilities',
          component: ExtranetAvailabilities,
          beforeEnter: async () => {
            const security = useSecurityStore()
            if (!security.isAdmin) return '/extranet/availabilities'
          },
        },
        {
          path: 'users',
          name: 'extranet-users',
          component: ExtranetUsers,
          beforeEnter: () => {
            const security = useSecurityStore()
            if (!security.isAdmin && !security.isAdminReadonly) return '/extranet'
          }
        },
        {
          path: '',
          name: 'extranet-dashboard',
          component: ExtranetDashboard
        },
        {
          path: 'missing_material',
          name: 'extranet-missing',
          component: ExtranetMissing
        },
        {
          path: 'billing',
          name: 'extranet-billing',
          component: ExtranetBilling
        },
        {
          path: 'billing/:id',
          name: 'extranet-consultant-billing',
          component: ExtranetBilling,
          beforeEnter: async () => {
            const security = useSecurityStore()
            if (!security.isAdmin) return '/extranet/billing'
          },
        },
      ]
    }
  ]
})

export default router
