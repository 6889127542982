<template>
  <div class="input-consultant-wrapper">
    <div ref="select" class="input-consultant" :class="{ open: open }">
      <label :for="id">{{ label ?? 'Consultant' }} :</label>
      <div class="input" @click="open = !open">
        <input
          :id="id"
          type="text"
          :value="selected_value ? selected_value.name : ''"
          :placeholder="placeholder"
        />
      </div>
      <ChevronUp class="input-consultant-chevron" @click="open = !open" />
      <X
        v-if="selected_value"
        @click="selected_value = undefined"
        class="input-consultant-clear"
        :stroke-width="1.5"
        :size="20"
      />
      <div class="input-consultant-options">
        <div class="input-skills-search">
          <input placeholder="Rechercher.." v-model="search_input" />
        </div>
        <div class="options">
          <div v-for="(option, key) in getOptions()" :key="key" class="input-consultant-option">
            <div @click="selectConsultant(option)">
              <CommonChip
                title="Le consultant possède les compétences pour cette mission."
                v-if="option.has_skills"
                empty
                filled
                color="success"
              ></CommonChip>
              <p>
                {{ option.name }}
              </p>
              <p>-</p>
              <CommonChip
                transparent
                :color="option.availability ? 'success' : 'danger'"
                size="medium"
                >{{ option.availability ? 'Disponible' : 'Indisponible' }}</CommonChip
              >
              <p v-if="option.zone">-</p>
              <p v-if="option.zone">Zone {{ option.zone }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { ChevronUp } from 'lucide-vue-next'
import { computed, onMounted, ref } from 'vue'
import type { ConsultantSearch } from 'types/api/consultant'
import { useUserStore } from '@/stores/gae/store_user'
import { X } from 'lucide-vue-next'
import CommonChip from '../CommonChip.vue'
import CommonIcon from '../CommonIcon.vue'

const emits = defineEmits(['update:modelValue'])
const user_store = useUserStore()

const props = defineProps<{
  id: string
  modelValue?: ConsultantSearch
  label?: string
  placeholder?: string
}>()

const open = ref(false)
const select = ref()
const search_input = ref('')

function selectConsultant(option: ConsultantSearch) {
  selected_value.value = option
  search_input.value = ''
  open.value = false
}

function getOptions() {
  if (search_input.value.length > 1) {
    return user_store.getSearchResults.filter(
      (e: ConsultantSearch) =>
        e.name.toLowerCase().includes(search_input.value.toLowerCase()) ||
        e.acronym.toLowerCase().includes(search_input.value.toLowerCase())
    )
  } else {
    return user_store.getSearchResults
  }
}

const selected_value = computed({
  get: () => {
    if (!props.modelValue) return undefined
    return props.modelValue
  },
  set: (v?: ConsultantSearch): void => {
    emits('update:modelValue', v)
  }
})

onClickOutside(select, () => (open.value = false))
</script>

<style scoped lang="scss">
.selected-value {
  top: 0;
  width: calc(100% - 60px);
  padding: 20px;
  position: absolute;
}

.input {
  position: relative;
}

.input-consultant {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-consultant-options {
  top: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--vc-white);
  border: 0px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 0px 0px 5px 5px;
  z-index: 100;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 0px;
  overflow: scroll;
  position: relative;
}

.input-consultant-chevron {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  top: 50px;
  right: 20px;
  cursor: pointer;
}

.input-consultant-clear {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  position: absolute;
  top: 50px;
  right: 50px;
}

.input-consultant > label {
  margin-bottom: 15px;
  pointer-events: none;
}

.input-consultant > div {
  cursor: pointer;
}

.input-consultant > div > input {
  pointer-events: none;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  background-color: transparent;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px;
}

.input-consultant-options::-webkit-scrollbar {
  display: none;
}

.input-consultant.open .input-consultant-options {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 300px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-top: unset;
}

.input-consultant.open .input-consultant-options .input-consultant-option > div {
  border-top: 1px solid rgba(var(--vcrgb-dark), 0.2);
}

.input-consultant-option > div {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-consultant-option {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  padding: 0px 20px;
}

.input-consultant-option:hover {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: rgba(var(--vcrgb-dark), 0.05);
}

.error > div > input {
  margin-bottom: 5px;
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}

.input-consultant.open > div > input {
  border-bottom: unset;
  border-radius: 5px 5px 0px 0px;
}

.input-consultant.open > .input-consultant-chevron {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotate(-180deg);
}

.input {
  position: relative;
}

.input-skills-search {
  padding: 0 20px;
  position: sticky;
  top: 0;
  background-color: var(--vc-white);
}

.input-skills-search > input {
  border: 0;
  top: 0;
  border-top: 1px solid rgba(var(--vcrgb-dark), 0.2);
  width: 100%;
  padding: 10px 0;
}
</style>
