<template>
  <div class="layout-two-column">
    <slot />
  </div>
</template>

<style scoped>
.layout-two-column {
  display: grid;
  gap: 30px;
}

@media screen and (min-width: 768px) {
  .layout-two-column {
    grid-template-columns: 1fr 1fr;
    gap: 20px 30px;
  }
}
</style>
