// Right side of the viewport

import { onMounted, onUnmounted, ref } from 'vue'

interface OnScrollEvent {
  (scroll: number): void
}

export function useViewport(onScroll: OnScrollEvent) {
  const scrollY = ref(0)
  const height = ref(0)
  const scrollPercent = ref(0)

  function update() {
    scrollY.value = window.scrollY
    const h = document.body.offsetHeight - height.value
    if (h < 0) {
      scrollPercent.value = 1
    } else {
      scrollPercent.value = Math.round((scrollY.value / h) * 100) / 100
    }
    onScroll(scrollPercent.value)
  }

  function resize() {
    height.value = window.innerHeight
  }

  onMounted(() => {
    window.addEventListener('scroll', update)
    window.addEventListener('resize', resize)
    resize()
    update()
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', update)
  })

  return { scrollY, height, scrollPercent }
}
