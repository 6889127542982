import type { EventInput } from '@fullcalendar/core/index.js'
import type { Intervention, InterventionFilters, InterventionRequest } from 'types/api/intervention'

type SmallCalendarEventProps = {
  extendedProps: {
    status: number
    count: number
  }
}

type AvailabilityCalendarEventProps = {
  extendedProps?: {
    requests: InterventionRequest[]
  }
}

export type SmallCalendarEvent = EventInput & SmallCalendarEventProps
export type AvailabilityCalendarEvent = EventInput & AvailabilityCalendarEventProps
export type InterventionDayItem = {
  interventions: Intervention[]
  event: SmallCalendarEvent
}

function getInterventionStatus(intervention: Intervention): number {
  let value = 0

  const mission_status = intervention.missionStatus

  if (mission_status === 'in_progress' || mission_status === 'pending_documents' || mission_status === 'documents_uploaded') {
    value = 1
  }

  if (mission_status === 'incomplete') {
    value = 2
  }

  return value
}

export function makeCalendarEvent(intervention: Intervention): SmallCalendarEvent {
  return {
    date: intervention.date,
    extendedProps: {
      status: getInterventionStatus(intervention),
      count: 1
    }
  }
}

export function getEventDensity(count: number, highest: number) {
  const value = count / highest
  return `rgba(var(--vcrgb-danger), ${value})`
}

/**
 * Takes a raw array of interventions and convert it to an array map of
 * InterventionDayItem. Which is a tuple of interventions and calendar event
 *
 * @todo Handle status with API data
 * @param interventions Array of interventions from API
 * @returns Map of InterventionDayItem
 */
export function convertToDayMap(interventions: Intervention[]): Map<string, InterventionDayItem> {
  const map = new Map<string, InterventionDayItem>()
  for (let i = 0; i < interventions.length; i++) {
    const intervention = interventions[i]
    const day = map.get(intervention.date)

    if (!day) {
      map.set(intervention.date, {
        interventions: [intervention],
        event: makeCalendarEvent(intervention)
      })
      continue
    }

    const status = getInterventionStatus(intervention)
    if (status > day.event.extendedProps.status) day.event.extendedProps.status = status

    day.interventions.push(intervention)
    day.interventions = day.interventions.sort((a, b) => {
      return a.startTime && b.startTime ? a.startTime.localeCompare(b.startTime) : 0
    })

    day.event.extendedProps.count++
  }

  // return a sorted map of days
  return map
}

/**
 * Process filters on an InterventionDayItem Interventions.
 *
 * The filter process will copy the interventions into a new Array. Then
 * process it and remove each items that doesn't meet the requirements
 *
 * @param day_item The current day to process
 * @param filters A representation of an Intervention with valid values as array
 * @returns The new filtered day item. (interventions as a copy)
 */
export function applyFilters(
  day_item: InterventionDayItem,
  filters: InterventionFilters
): InterventionDayItem {
  if (!hasFilter(filters)) {
    return day_item
  }

  const interventions: Intervention[] = [...day_item.interventions]
  let delete_count = 0
  day_item.interventions.forEach((intervention, index) => {
    const check = (a: string[] | undefined, b?: string) => {
      if (a && a.length > 0) {
        if (!b) {
          interventions.splice(index - delete_count, 1)
          delete_count++
        } else if (!a.some((e) => b === e)) {
          interventions.splice(index - delete_count, 1)
          delete_count++
        }
      }
    }

    const status_check = (a: string[] | undefined, b: string) => {
      if (a && a.length > 0 && !a.some((e) => b === e)) {
        interventions.splice(index - delete_count, 1)
        delete_count++
      }
    }

    const boolean_check = (a: boolean | undefined, b: boolean) => {
      if (a !== undefined && a !== b) {
        interventions.splice(index - delete_count, 1)
        delete_count++
      }
    }

    if (filters.skills && filters.skills.length > 0) {
      if (!filters.skills.some((e) => intervention.skills.map((e) => e.shortname).includes(e))) {
        interventions.splice(index - delete_count, 1)
        delete_count++
      }
    }

    check(filters.consultant, intervention.consultant?.id.toString())
    check(filters.commercial, intervention.salesperson?.id.toString())
    check(filters.refered, intervention.referent?.id.toString())
    check(filters.client, intervention.customer)
    check(filters.backer, intervention.backer)
    status_check(filters.clientStatus, intervention.customerStatus)
    status_check(filters.consultantStatus, intervention.consultantStatus)
    status_check(filters.missionStatus, intervention.missionStatus)
    boolean_check(filters.isDocumentFolderSent, intervention.isDocumentFolderSent)

    // TODO: DELETED
  })

  return {
    ...day_item,
    interventions
  }
}

function hasFilter(filters: InterventionFilters): boolean {
  if (Object.keys(filters).length == 0) return false
  let property: keyof typeof filters
  for (property in filters) {
    const values = filters[property]
    if (values !== undefined) return true
  }

  return false
}
