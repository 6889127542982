<template>
  <div class="common-card">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.common-card {
  border-radius: 5px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  padding: 20px;
}
</style>
