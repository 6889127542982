<template>
  <div class="composite-chart">
    <div class="chart-wrapper">
      <Doughnut
        :data="chartData"
        :options="chartOptions"
        :width="props.displayOutlabels ? 300 : 200"
        :height="props.displayOutlabels ? 300 : 200"
      />
      <template v-if="{ label }">
        <p>{{ label }}</p>
      </template>
    </div>
    <div v-if="separator" class="separator"></div>
    <div id="legend-container" v-if="legend">
      <CommonChartLegend :legendValues="legendValues" :lowestPercentage="props.lowestIndexes" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Doughnut } from 'vue-chartjs'
import CommonChartLegend from '../common/CommonChartLegend.vue'
import { computed } from 'vue'
import { Chart as ChartJS, Title, Tooltip, ArcElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import outlabeledPie from '@energiency/chartjs-plugin-piechart-outlabels'

type chartDataType = {
  labels: string[]
  datasets: { backgroundColor?: string[]; data: number[] }[]
}
const props = withDefaults(
  defineProps<{
    label?: string
    separator?: boolean
    legend?: boolean
    dataLabels?: 'percentage' | 'value' | 'hours'
    displayDataLabels?: boolean
    displayOutlabels?: boolean
    lowestIndexes?: number[]
    chartData: chartDataType
  }>(),
  {
    separator: false,
    legend: false,
    displayDataLabels: false,
    displayOutlabels: false,
    dataLabels: 'value'
  }
)

ChartJS.register(
  Title,
  Tooltip,
  ArcElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels,
  outlabeledPie
)
// Map the legend values from the chart data
const legendValues = computed(() =>
  props.chartData.labels.map((label, index) => {
    return {
      label: label,
      color: props.chartData.datasets[0].backgroundColor![index]
    }
  })
)

const chartOptions = computed(() => ({
  responsive: false,
  maintainAspectRatio: false,
  borderWidth: 0,
  cutout: '30%',
  layout: {
    padding: props.displayOutlabels ? 45 : 0
  },

  plugins: {
    outlabels: {
      display: props.displayOutlabels ? true : false,
      text: function (context: any) {
        var index = context.dataIndex
        var value = context.dataset.data[index]
        if (value === 0) {
          return ''
        }
        if (props.dataLabels === 'percentage') {
          return '%p'
        }
        if (props.dataLabels === 'hours') {
          return '%v h'
        } else {
          return '%v'
        }
      },
      color: 'black',
      backgroundColor: 'white',
      stretch: 10,
      textAlign: 'center',
      font: {
        resizable: true,
        minSize: 8,
        maxSize: 10
      }
    },
    tooltip: {
      callbacks: {
        title: function (context: any) {
          return context[0].label
        },
        label: function (context: any) {
          let label

          if (props.dataLabels === 'percentage') {
            const formattedValue = chartOptions.value.plugins.datalabels.formatter(
              context.parsed,
              context
            )
            label = formattedValue + ' des interventions'
          } else if (props.dataLabels === 'hours') {
            label = context.parsed + ' heures'
          } else {
            label = context.parsed + ' interventions'
          }
          return label
        }
      }
    },
    // Data displayed on the graph
    datalabels: {
      display: props.displayDataLabels ? true : false,
      // Used for tooltip / datalabels
      formatter: (value: number, ctx: any) => {
        if (value === 0) {
          return ''
        }
        if (props.dataLabels === 'percentage') {
          let sum = 0
          let dataArr = ctx.chart.data.datasets[0].data
          dataArr.map((data: number) => {
            sum += data
          })
          let percentage = ((value * 100) / sum).toFixed(1) + '%'
          return percentage
        } else if (props.dataLabels === 'value') {
          return value
        } else if (props.dataLabels === 'hours') {
          return value + 'h'
        }
      },
      color: '#fff'
    }
  }
}))
</script>

<style scoped lang="scss">
.composite-chart {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.separator {
  width: 1px;
  // With height 100% it doesn't appear on storybook
  height: 200px;
  background-color: rgba(var(--vcrgb-dark), 0.2);
}
</style>
