<template>
  <div class="composite-todo">
    <div
      v-for="item in items"
      class="composite-todo-option"
      :key="item.label"
      @click="select(item.value)"
    >
      <div class="composite-todo-option-inner">
        <Check
          v-if="!readonly"
          class="composite-todo-option-icon"
          color="var(--vc-white)"
          :size="16"
        />
        <input v-if="!readonly" type="checkbox" v-model="selected" :value="item.value" />
        <p>{{ item.label }}</p>
      </div>
      <div @click.stop="remove(item.value)" v-if="!security.isAdminReadonly">
        <X :size="10" style="margin-left: 5px" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Check } from 'lucide-vue-next'
import { computed } from 'vue'
import { X } from 'lucide-vue-next'
import { useSecurityStore } from '@/stores/gae/store_security'

const security = useSecurityStore()

const props = defineProps<{
  readonly?: boolean
  editable?: boolean
  items: TodoOption[]
  modelValue?: (string | number)[]
}>()

const emits = defineEmits(['update:modelValue', 'onClear'])

export type TodoOption = {
  label: string
  value: string
}

const selected = computed({
  get: () => {
    return props.modelValue ?? []
  },
  set: (v?: (string | number)[]): void => {
    emits('update:modelValue', v)
  }
})

function select(value: string | number) {
  const index = selected.value.findIndex((v) => v === value)
  if (index == -1) {
    selected.value.push(value)
  } else {
    selected.value.splice(index, 1)
  }
  emits('update:modelValue', selected.value)
}

function remove(option: string) {
  if (selected.value) {
    const i = selected.value.findIndex((e) => e == option)
    selected.value.splice(i, 1)
    emits('onClear', option)
  }
}
</script>

<style scoped>
input[type='checkbox'] {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  background-color: var(--vc-white);
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  cursor: pointer;
}

input[type='checkbox']:checked {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: var(--vc-primary);
}

.composite-todo-option-icon {
  position: absolute;
  z-index: 20;
  left: 0;
  width: 20px;
  pointer-events: none;
}

.composite-todo-option {
  position: relative;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.composite-todo-option-inner {
  display: flex;
  gap: 10px;
  align-items: center;
}

.composite-todo-option:not(:last-child) {
  border-bottom: 1px solid rgba(var(--vcrgb-dark), 0.2);
}

.composite-todo-option > p {
  cursor: pointer;
  user-select: none;
}
</style>
