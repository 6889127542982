<template>
  <div class="layout-modal-content">
    <slot />
  </div>
</template>

<style scoped>
.layout-modal-content {
  padding: 0 30px;
}

@media screen and (min-width: 640px) {
  .layout-modal-content {
    padding: 0 50px;
  }
}
</style>
