<template>
  <div ref="interventions" class="composite-intervention-list">
    <template v-for="[day, value] of intervention_store.getRenderedDays" :key="day">
      <div v-if="value.interventions.length > 0">
        <div class="dayname">
          <CommonChip filled color="dark" size="medium">{{ getDayDate(day) }}</CommonChip>
          <p>
            {{ value.interventions.length }} intervention{{
              value.interventions.length > 1 ? 's' : ''
            }}
          </p>
        </div>
        <div class="interventions">
          <CompositeInterventionCard
            v-for="(intervention, key) of value.interventions"
            :key="key"
            :intervention="intervention"
          />
        </div>
      </div>
    </template>
    <div class="no-data" v-if="intervention_store.getRenderedCount == 0">
      <AlertTriangle />
      Aucune intervention n'a été trouvée pour cette plage de date.
    </div>
    <CommonButton class="mt-30" icon="ArrowRight" @click="intervention_store.renderNextMonth()">
      Mois suivant
    </CommonButton>
  </div>
</template>

<script setup lang="ts">
import { useInterventionStore } from '@/stores/gae/store_intervention'
import CompositeInterventionCard from './CompositeInterventionCard.vue'
import CommonChip from '../common/CommonChip.vue'
import CommonButton from '../common/CommonButton.vue'
import { useViewport } from '@/composable/viewport'
import { nextTick, onMounted, ref, watch } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import { AlertTriangle } from 'lucide-vue-next'

const intervention_store = useInterventionStore()
const interventions = ref<HTMLDivElement>()

useViewport((scroll: number) => {
  if (scroll > 0.75 && !lastVisible()) {
    intervention_store.renderNext()
  }
})

function lastVisible() {
  const last = intervention_store.getLastDay
  if (!last) return false

  return intervention_store.getRenderedDays.get(last) != undefined
}

function getDayDate(string_date: string) {
  const date = new Date(string_date)
  const options = { month: 'long', day: 'numeric' }
  // Missing option type for typescript.
  // @ts-ignore
  return date.toLocaleDateString('fr-FR', options)
}

watch(
  () => intervention_store.interventions,
  (_) => {
    fill()
  }
)

useResizeObserver(interventions, async () => {
  fill()
})

function fill() {
  if (intervention_store.getRenderedDays.size == 0) return

  nextTick(() => {
    if (lastVisible()) {
      return
    }

    if (window.innerHeight >= document.body.clientHeight) {
      intervention_store.renderNext()
      fill()
    }
  })
}

onMounted(() => {
  fill()
})
</script>

<style scoped>
.composite-intervention-list {
  width: 100%;
}

.dayname {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.dayname > p {
  color: rgba(var(--vcrgb-dark), 0.75);
}

.interventions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.no-data {
  margin-top: 30px;
  color: rgba(var(--vcrgb-dark), 0.6);
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
