<template>
  <div class="request-confirm">
    <CommonButton @click="refuse" icon="X">Refuser l'intervention</CommonButton>
    <CommonButton @click="accept" icon="Check" filled>Accepter l'intervention</CommonButton>
  </div>
</template>

<script setup lang="ts">
import type { InterventionRequest } from 'types/api/intervention'
import CommonButton from '../common/CommonButton.vue'
import { useRequestStore } from '@/stores/gae/store_requests'

const props = defineProps<{
  request: InterventionRequest
}>()

const request_store = useRequestStore()

function accept() {
  if (!confirm("Confirmer l'action ?")) return
  request_store.accept(props.request)
}

function refuse() {
  if (!confirm("Confirmer l'action ?")) return
  request_store.refuse(props.request)
}
</script>

<style scoped>
@keyframes appear {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.request-confirm {
  display: flex;
  transform: translateY(100%);
  animation: appear 1s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  animation-delay: 1000ms;
  border-top: 1px solid rgba(var(--vcrgb-dark), 0.2);
  box-sizing: border-box;
  background-color: white;
  width: inherit;
  padding: 20px 50px;
  justify-content: end;
  gap: 20px;
}
</style>
