<template>
  <div
    v-if="items.length > 0"
    class="input-button-list"
    :class="{ open, bot: y > height / 2 }"
    ref="button"
  >
    <button @click="open = true">
      <div class="input-button-list-label">
        <p class="input-button-list-count" v-if="modelValue && modelValue.length > 0">
          {{ modelValue?.length }}
        </p>
        <p class="label">{{ label }}</p>
      </div>
      <ChevronRight class="input-button-list-icon" />
    </button>
    <div ref="menu" class="input-button-list-menu" :class="{ bot: y > height / 2 }">
      <div v-for="option of items" :key="option.value" class="option" @click="select(option.value)">
        <Check class="icon" color="var(--vc-white)" :size="16" />
        <input type="checkbox" v-model="selected" :value="option.value" />
        <p>{{ option.label }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, useElementBounding, useWindowSize } from '@vueuse/core'
import { Check } from 'lucide-vue-next'
import { ChevronRight } from 'lucide-vue-next'
import { computed, ref } from 'vue'

const props = defineProps<{
  items: { label: string; value: string | number }[]
  label: string
  modelValue?: (string | number)[]
}>()
const emits = defineEmits(['update:modelValue'])

const open = ref(false)
const menu = ref<HTMLElement>()
const button = ref<HTMLElement>()
onClickOutside(menu, () => {
  open.value = false
})
const { height } = useWindowSize()
const { y } = useElementBounding(button)
const selected = computed({
  get: () => {
    return props.modelValue ?? []
  },
  set: (v?: (string | number)[]): void => {
    emits('update:modelValue', v)
  }
})

function select(value: string | number) {
  const index = selected.value.findIndex((v) => v === value)
  if (index == -1) {
    selected.value.push(value)
  } else {
    selected.value.splice(index, 1)
  }
  emits('update:modelValue', selected.value)
}
</script>

<style scoped lang="scss">
input[type='checkbox'] {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  background-color: var(--vc-white);
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  cursor: pointer;
}

input[type='checkbox']:checked {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: var(--vc-dark);
}

.input-button-list {
  position: relative;
  width: 240px;
}

.input-button-list > button {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--vc-white);
  border: rgba(var(--vcrgb-dark), 0.2) solid 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  padding: 16px 20px;
}
.icon {
  position: absolute;
  z-index: 20;
  left: 0;
  width: 20px;
  pointer-events: none;
}

.input-button-list.open > button {
  color: var(--vc-white);
  background-color: var(--vc-dark);
}

.input-button-list.open > button > .input-button-list-label p {
  color: var(--vc-white);
}

.input-button-list > button > .input-button-list-icon {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
}

.input-button-list.open > button > .input-button-list-icon {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotate(90deg);
}
.input-button-list.open.bot > button > .input-button-list-icon {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotate(-90deg);
}

.input-button-list.open > .input-button-list-menu {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 1;
  left: calc(100% + 5px);
  pointer-events: all;
}

.input-button-list-menu.bot {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  top: unset;
  bottom: 0;
}

.input-button-list-menu {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  left: calc(100%);
  position: absolute;
  background-color: var(--vc-white);
  z-index: 10;
  top: 0;
  bottom: unset;
  border: rgba(var(--vcrgb-dark), 0.2) solid 1px;
  padding: 5px 20px;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  max-height: 500px;
  overflow-y: scroll;
}

.input-button-list-menu .option:last-child {
  margin-bottom: 0;
  border: none;
}

.option {
  position: relative;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  padding: 15px 0;
  min-width: 175px;
  border-bottom: 1px solid rgba(var(--vcrgb-dark), 0.2);
}

.option > p {
  cursor: pointer;
  user-select: none;
}

.option input {
  min-width: 20px;
}

@media screen and (max-width: 640px) {
  .input-button-list {
    width: 100%;
  }

  .input-button-list-menu {
    transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
    top: 0;
    bottom: unset;
    left: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .input-button-list.open > .input-button-list-menu {
    transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 1;
    left: 0;
    top: calc(100% + 5px);
    pointer-events: all;
  }

  .input-button-list-menu.bot {
    transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
    top: calc(100%);
    bottom: unset;
  }
}

.input-button-list-label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  text-align: left;
}

.input-button-list-count {
  border-radius: 50%;
  background-color: rgba(var(--vcrgb-dark), 0.2);
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
</style>
