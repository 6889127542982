import type {
    AdminConsultantBillingDTO,
    AdminDashboardStats,
    ConsultantDashboardStats,
    ConsultantActivityDTO,
    ConsultantBillingDTO,
    ActivityDTO,
    zoneDistributionDTO,
    UndercoveredDepartmentDTO,
} from 'types/api/stats'
import { defineStore } from 'pinia'
import { useGAEAPI } from '@/composable/gae_api'
import { handleErrorNotification } from '@/services/service_api'
import { getDateYMDsync } from '@/services/service_date';

const currentStart = new Date();
const currentEnd = new Date()
const year = new Date();
currentStart.setMonth(0, 1);
currentEnd.setFullYear(currentEnd.getFullYear(), 11, 31);

type StatsStoreState = {
    activity: ActivityDTO
    zoneDistribution: zoneDistributionDTO
    undercoveredDepartments: UndercoveredDepartmentDTO
    isFetching?: boolean
    date?: Date[]
    consultantActivity: ConsultantActivityDTO
    consultantBilling: ConsultantBillingDTO
    adminConsultantBilling: AdminConsultantBillingDTO
    consultantDate: number
}

export const useStatsStore = defineStore('stats', {
    state: (): StatsStoreState => ({
        activity: {
            countInterventions: 0,
            countInterventionsHalfDay: 0,
            countInterventionsFullDay: 0,
            distribution: []
        },
        zoneDistribution: [],
        undercoveredDepartments: [],
        consultantActivity: {
            countInterventions: 0,
            countInterventionsHalfDay: 0,
            countInterventionsFullDay: 0,
            countInterventionsCanceled: 0,
            countInterventionsRefused: 0,
            averageDocumentSendingDelay: 0,
            distribution: []
        },
        consultantBilling: {
            turnover: 0,
            formationTurnover: 0,
            averageInterventionCost: 0,
            travelExpenses: 0,
            additionalFees: 0,
            averageChargeAmount: 0,
            formationAverageInterventionCost: 0,
            formationTravelExpenses: 0,
            formationAdditionalFees: 0,
            formationAverageChargeAmount: 0,
        },
        adminConsultantBilling: {
            currentYearCosts: 0,
            pastMonthCosts: 0,
            currentMonthCosts: 0,
            averageInterventionCost: 0,
            interventionFees: 0,
            travelExpenses: 0,
            additionalFees: 0,
            averageChargeAmount: 0,
        },
        date: [currentStart, currentEnd],
        consultantDate: year.getFullYear()
    }),
    getters: {
        getActivity: (state) => state.activity,
        getActivityDistribution: (state) => state.activity.distribution.filter((i) => i.countFullDays > 0 || i.countHalfDays > 0),
        getZoneDistribution: (state) => state.zoneDistribution.filter((d) => d.percentage > 0),
        getUndercoveredDepartments: (state) => state.undercoveredDepartments,
        getDate: (state) => state.date,
        getConsultantDate: (state) => state.consultantDate,
        getConsultantActivity: (state) => state.consultantActivity,
        getConsultantBilling: (state) => state.consultantBilling,
        getBilling: (state) => state.adminConsultantBilling
    },
    actions: {
        async fetchAdminStats() {
            const api = useGAEAPI()
            this.isFetching = true

            if (!this.date) {
                return
            }

            return api.instance
                .get<AdminDashboardStats>(`/api/admin_dashboard_stats`, {
                    headers: {
                        "Content-Type": 'application/json',
                        Accept: 'application/json'
                    },
                    params: {
                        dateFrom: getDateYMDsync(this.date[0]),
                        dateTo: getDateYMDsync(this.date[1])
                    }
                })
                .then((response) => {
                    this.activity = response.data.activity,
                        this.zoneDistribution = response.data.zoneDistribution,
                        this.undercoveredDepartments = response.data.undercoveredDepartmentsRanking
                    this.adminConsultantBilling = response.data.billing
                })
                .catch(handleErrorNotification)
                .finally(() => {
                    this.isFetching = false
                })
        },

        async fetchConsultantStats(id: number) {
            const api = useGAEAPI()
            this.isFetching = true

            if (!this.consultantDate) {
                return
            }

            return api.instance
                .get<ConsultantDashboardStats>(`/api/consultant_dashboard_stats`, {
                    headers: {
                        "Content-Type": 'application/json',
                        Accept: 'application/json'
                    },
                    params: {
                        id: id,
                        year: this.consultantDate
                    }
                })
                .then((response) => {
                    this.consultantActivity = response.data.activity
                    this.consultantBilling = response.data.billing
                })
                .catch(handleErrorNotification)
                .finally(() => {
                    this.isFetching = false
                })
        }
    }
})
