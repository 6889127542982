<template>
  <img class="logo" :src="LogoGAE" />
  <h1 class="mb-20">Modifier votre mot de passe</h1>
  <p class="mb-40">Veuillez indiquer un nouveau mot de passe pour votre compte GAE conseil.</p>
  <form class="mb-30">
    <InputCommon
      type="password"
      v-model="v$.password.$model"
      :errors="v$.password.$errors"
      label="Nouveau mot de passe"
      class="mb-20"
      required
      id="email"
    />
    <InputCommon
      type="password"
      v-model="v$.repeat.$model"
      :errors="v$.repeat.$errors"
      label="Confirmer le nouveau mot de passe"
      required
      id="email"
    />
  </form>
  <CommonButton class="return mb-20" @click="submit" filled :loading="security.isFetching">
    Confirmer la modification
  </CommonButton>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import LogoGAE from '@/assets/images/logo-gae-color.png'
import { computed, onMounted, reactive } from 'vue'
import { helpers, required, sameAs } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import InputCommon from '@/components/common/input/InputCommon.vue'
import { useRoute } from 'vue-router'
import { useSecurityStore } from '@/stores/gae/store_security'

const route = useRoute()
const token = route.params.token
const security = useSecurityStore()

const form = reactive({
  password: '',
  repeat: ''
})

const rules = {
  password: {
    required: helpers.withMessage('Veuillez indiquer un nouveau mot de passe.', required)
  },
  repeat: {
    sameAsPassword: helpers.withMessage(
      'Les deux mots de passe doivent être identiques',
      sameAs(computed(() => form.password))
    )
  }
}

const v$ = useVuelidate(rules, form)

function submit() {
  v$.value.$validate().then((valid) => {
    if (valid) {
      security.resetPassword(form.password, token as string)
    }
  })
}
</script>

<style scoped>
.icon {
  display: block;
}
.return {
  width: 100%;
}

.logo {
  margin-bottom: 80px;
}
</style>
