<template>
  <LayoutModal ref="modal">
    <CommonHeader class="mb-30" title="Créer une intervention">
      <template #action>
        <CommonReturn @click="modal?.close()" />
      </template>
    </CommonHeader>
    <LayoutModalContent>
      <FormIntervention ref="form" />
      <CommonButton
        @click="submit"
        class="submit"
        icon="Plus"
        filled
        :loading="intervention_store.isFeching"
      >
        Créer l'intervention
      </CommonButton>
    </LayoutModalContent>
  </LayoutModal>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import LayoutModal from '@/components/layout/LayoutModal.vue'
import LayoutModalContent from '@/components/layout/LayoutModalContent.vue'
import { nextTick, ref } from 'vue'
import FormIntervention from '../forms/FormIntervention.vue'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import CommonReturn from '@/components/common/CommonReturn.vue'
import { useToast } from 'vue-toast-notification'
import { getContract } from '@/services/service_intervention'
import { useUserStore } from '@/stores/gae/store_user'
import type { Intervention } from 'types/api/intervention'

defineExpose({ create })
const intervention_store = useInterventionStore()
const user_store = useUserStore()
const modal = ref<InstanceType<typeof LayoutModal>>()
const form = ref<InstanceType<typeof FormIntervention>>()
const toast = useToast()

async function create(intervention?: Intervention) {
  if (modal.value) {
    modal.value.open().then(() => {
      if (intervention) {
        const interventionCopy = { ...intervention }
        interventionCopy.id = 0
        interventionCopy.consultant = undefined
        interventionCopy.missionStatus = 'incomplete'
        form.value?.load(interventionCopy)
      }
    })
    user_store.clearSearch()
  }
}

async function submit() {
  if (!form.value) return
  const validation = await form.value.getValidation()
  if (!validation) {
    const toast = useToast()
    toast.error('Certains champs sont invalides.')
    return
  }

  const contract = getContract(form.value.getData())
  if (contract) {
    const modalValue = modal.value
    intervention_store.post(contract).then(() => {
      modalValue?.close()
    })
  }
}
</script>

<style scoped>
.submit {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 150px;
}
</style>
