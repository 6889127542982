import { useGAEAPI } from '@/composable/gae_api'
import { defineStore } from 'pinia'
import type { APIAppdataResponse, ServiceCategory, Skill } from 'types/api/appdata'
import type { Pack } from 'types/api/material'
import type { User } from 'types/api/user'

type AppDataStoreState = {
  admins: User[]
  skills: Skill[]
  service_categories: ServiceCategory[]
  intervention_status: { label: string; color: ApplicationColors }[]
  packs: Pack[]
}

export const useAppData = defineStore('appdata', {
  state: (): AppDataStoreState => ({
    admins: [],
    skills: [],
    packs: [],
    service_categories: [],
    intervention_status: [
      { label: 'Incomplet', color: 'danger' },
      { label: 'FM En cours', color: 'warning' },
      { label: 'En cours', color: 'warning' },
      { label: 'FM Complet', color: 'success' },
      { label: 'Complet', color: 'success' },
      { label: 'Autre', color: 'success' }
    ]
  }),
  getters: {
    getSkills(state) {
      const output = []
      for (const category of state.service_categories) {
        for (const skill of category.skills) {
          output.push(skill)
        }
      }

      output.sort((a, b) => a.name.localeCompare(b.name));
      return output
    },
    getCategory(state) {
      const output = []
      for (const category of state.service_categories) {
        output.push(category)
      }
    },
    getAdmins: (state) => state.admins,
    getAdminsOptions: (state): SelectOption[] => {
      return state.admins.map(e => {
        return {
          label: `${e.firstname} ${e.lastname}`,
          value: e.id.toString()
        }
      })
    },
    getPackOptions: (state) => state.packs.map(e => ({ label: e.name, value: e.id.toString() }))
  },
  actions: {
    async fetch() {
      const api = useGAEAPI()
      return api.instance.get<APIAppdataResponse>(`/api/appdatas`).then((response) => {
        this.service_categories = response.data.categories
        this.admins = response.data.admins;
        this.packs = response.data.packs;
      })
    }
  }
})
