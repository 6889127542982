import { useGAEAPI } from "@/composable/gae_api";
import { ValueOrUndefined, handleErrorNotification } from "@/services/service_api";
import type { AvailabilityCalendarEvent } from "@/services/service_calendar";
import { getDateYMDsync, getDateYMsync } from "@/services/service_date";
import { defineStore } from "pinia";
import type { Availability, AvailabilityGETResponse, AvailabilityPOSTRequest } from "types/api/availability";

type AvailabilityStoreState = {
	availabilities: Map<string, Availability[]>
	fetching: boolean
}

export const useAvailabilityStore = defineStore("availability", {
	state: (): AvailabilityStoreState => ({
		availabilities: new Map<string, Availability[]>(),
		fetching: false
	}),
	getters: {
		isFetching: (state) => state.fetching,
		getAvailabilities: (state) => state.availabilities,
		getAvailabilitiesEvents: (state): AvailabilityCalendarEvent[] => {
			const availabilities = Array.from(state.availabilities.entries()).flatMap(([id, elements]) =>
				elements.map(element => ({
					id: element.startDate,
					date: new Date(element.startDate),
					allDay: true,
					className: 'fc-available'
				}))
			);

			return availabilities;
		}
	},
	actions: {
		async fetchAvailabilitiesPrevNext(id: number, date: Date) {
			const prev = new Date(date);
			prev.setMonth(date.getMonth() - 1)
			const next = new Date(date);
			next.setMonth(date.getMonth() + 1)

			this.fetchAvailabilities(id, prev)
			this.fetchAvailabilities(id, date)
			this.fetchAvailabilities(id, next)
		},
		async fetchAvailabilities(id: number, date: Date) {
			const date_str = getDateYMsync(date);
			if (this.availabilities.get(date_str)) {
				return
			}

			const api = useGAEAPI();
			this.fetching = true;
			api.instance.get<AvailabilityGETResponse>(`/api/consultants/${id}/availabilities`, {
				headers: {
					Accept: "application/json"
				},
				params: {
					month: date.getMonth() + 1,
					year: date.getFullYear()
				}
			}).then(response => {
				this.availabilities.set(date_str, response.data);
			}).finally(() => {
				this.fetching = false;
			}).catch(handleErrorNotification)
		},
		async create(id: number, start: Date, end?: Date, days?: number[]) {
			const api = useGAEAPI();
			this.fetching = true;
			this.modify(start, end, days);
			return api.instance.post<never, any, AvailabilityPOSTRequest>(`/api/consultants/${id}/availabilities`, {
				startDate: getDateYMDsync(start),
				endDate: ValueOrUndefined(getDateYMDsync(end)),
				days: days,
			}).finally(() => {
				this.fetching = false
			}).catch(handleErrorNotification);
		},
		async remove(id: number, start: Date, end?: Date) {
			const api = useGAEAPI();
			this.fetching = true;
			this.modify(start, end, undefined, false);
			return api.instance.delete(`/api/consultants/${id}/availabilities`, {
				params: {
					start: getDateYMDsync(start),
					end: ValueOrUndefined(getDateYMDsync(end))
				}
			}).finally(() => {
				this.fetching = false;
			}).catch(handleErrorNotification);
		},
		find(date: Date) {
			const ymd = getDateYMDsync(date);
			const ym = getDateYMsync(date);

			const month = this.availabilities.get(ym);
			if (month) {
				return month.find(e => e.startDate === ymd);
			}
		},
		modify(start: Date, end?: Date, days?: number[], state = true) {
			const startDate = new Date(start);
			const endDate = new Date(end ?? start);
			while (startDate <= endDate) {

				const str = getDateYMsync(startDate);
				const month = this.availabilities.get(str);
				const date = getDateYMDsync(startDate);
				const index = month?.findIndex(e => e.startDate === date);

				if (state == false) {
					if (index !== undefined && index !== -1) {
						month?.splice(index, 1);
					}
					startDate.setDate(startDate.getDate() + 1);
					continue;
				}

				if (days && !days.includes(startDate.getDay() - 1)) {
					startDate.setDate(startDate.getDate() + 1);
					continue;
				}

				if (!month) {
					this.availabilities.set(str, [{ startDate: date }])
				} else {
					if (index === undefined || index === -1) {
						month.push({ startDate: date })
					}
				}

				startDate.setDate(startDate.getDate() + 1);
			}
		}
	}
})