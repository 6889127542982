<template>
  <img class="logo" :src="LogoGAE" />
  <div class="return">
    <ArrowLeft :size="16" />
    <RouterLink to="/">Retour</RouterLink>
  </div>
  <h1 class="mb-20">Mot de passe oublié ?</h1>
  <p class="mb-40">
    Indiquez votre adresse email dans le champs ci-dessous, et recevez un email vous permettant de
    réinitialiser votre mot de passe.
  </p>
  <form class="mb-30">
    <InputCommon
      v-model="v$.email.$model"
      :errors="v$.email.$errors"
      label="Email"
      required
      id="email"
    />
  </form>
  <CommonButton @click="submit" class="submit" filled :loading="security.isFetching">
    Recevoir un nouveau mot de passe
  </CommonButton>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import InputCommon from '@/components/common/input/InputCommon.vue'
import LogoGAE from '@/assets/images/logo-gae-color.png'
import { ArrowLeft } from 'lucide-vue-next'
import { reactive } from 'vue'
import { useSecurityStore } from '@/stores/gae/store_security'
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useRouter } from 'vue-router'

const security = useSecurityStore()

const form = reactive({
  email: ''
})

const rules = {
  email: { required: helpers.withMessage('Veuillez indiquer un email.', required) }
}

const v$ = useVuelidate(rules, form)

function submit() {
  v$.value.$validate().then((valid) => {
    if (valid) {
      security.requestPassword(form.email)
    }
  })
}
</script>

<style scoped>
.submit {
  width: 100%;
}

.return {
  cursor: pointer;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: fit-content;
  color: var(--vc-dark);
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
}
.return:hover {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  gap: 16px;
}

.logo {
  margin-bottom: 80px;
}
</style>
