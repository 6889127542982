<template>
  <div class="availability-calendar-actions">
    <div class="availability-calendar-title">
      <h3>{{ title }}</h3>
      <CommonLoader v-if="availability_store.isFetching" />
    </div>
    <div class="actions-buttons">
      <ModalAvailability v-if="!consultant" :user="getUserId()" />
      <CommonIconButton @click="prev" icon="ArrowLeft" />
      <CommonIconButton @click="next" icon="ArrowRight" />
    </div>
  </div>
  <FullCalendar ref="calendar" :options="options" class="availability-calendar">
    <template #eventContent="{ event }">
      <div v-if="event.extendedProps.requests">
        <CommonChip filled color="success" transparent>
          {{ event.extendedProps.requests.length }} Intervention
        </CommonChip>
      </div>
      <CommonChip
        v-else-if="event.extendedProps.isHolyday"
        filled
        transparent
        class="fc-availability-holyday"
      >
        Férié
        <p class="fc-availability-holyday-name">{{ event.title }}</p>
      </CommonChip>
    </template>
  </FullCalendar>
</template>

<script setup lang="ts">
import type { Calendar, CalendarOptions } from '@fullcalendar/core/index.js'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { type DateClickArg } from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/vue3'
import { onBeforeMount, onMounted, reactive, ref, watch } from 'vue'
import CommonIconButton from '../common/CommonIconButton.vue'
import { useAvailabilityStore } from '@/stores/gae/store_availability'
import { useSecurityStore } from '@/stores/gae/store_security'
import CommonLoader from '../common/CommonLoader.vue'
import ModalAvailability from './modal/ModalAvailability.vue'
import { useRequestStore } from '@/stores/gae/store_requests'
import CommonChip from '../common/CommonChip.vue'
import { useToast } from 'vue-toast-notification'
import { useCalendarStore } from '@/stores/gae/store_calendar'

const title = ref()
const calendar = ref<InstanceType<typeof FullCalendar>>()
const calendar_api = ref<Calendar | undefined>()
const availability_store = useAvailabilityStore()
const calendar_store = useCalendarStore()
const request_store = useRequestStore()
const security = useSecurityStore()

const props = defineProps<{
  consultant?: number
}>()

const options: CalendarOptions = reactive({
  plugins: [dayGridPlugin, interactionPlugin],
  initialView: 'dayGridMonth',
  headerToolbar: false,
  dayHeaderFormat: { weekday: 'long' },
  firstDay: 1,
  dayHeaderClassNames: 'fc-availability-header',
  dayCellClassNames: 'fc-availability-cell',
  locale: 'fr',
  height: 600,
  nowIndicatorClassNames: 'test',
  events: [],
  eventClick: () => false,
  dateClick: onDateClick
})

function getUserId() {
  if (props.consultant) return props.consultant
  if (security.user) return security.user.id
  throw 'Unable to process the UserID, make sure to load the component after the user is properly logged in.'
}

onBeforeMount(() => {
  watch(
    () => availability_store.getAvailabilitiesEvents,
    (events) => {
      options.events = events
        .concat(request_store.getRequestsEvents)
        .concat(calendar_store.getHolydaysEvents)
    }
  )
  watch(
    () => request_store.getRequestsEvents,
    (events) => {
      options.events = events
        .concat(availability_store.getAvailabilitiesEvents)
        .concat(calendar_store.getHolydaysEvents)
    }
  )
})

onMounted(() => {
  request_store.fetchRequests().then(() => {
    if (calendar.value) {
      availability_store.availabilities = new Map()
      availability_store.fetchAvailabilitiesPrevNext(getUserId(), new Date())
      calendar_api.value = calendar.value.getApi()
      title.value = calendar_api.value.view.title
    }
  })
})

function next() {
  if (calendar_api.value) {
    calendar_api.value.next()
    availability_store.fetchAvailabilitiesPrevNext(getUserId(), calendar_api.value.getDate())
    title.value = calendar_api.value.view.title
  }
}

function prev() {
  if (calendar_api.value) {
    calendar_api.value.prev()
    availability_store.fetchAvailabilitiesPrevNext(getUserId(), calendar_api.value.getDate())
    title.value = calendar_api.value.view.title
  }
}

function onDateClick(dateClickArg: DateClickArg) {
  if (props.consultant) {
    return
  }

  const event = availability_store.find(dateClickArg.date)
  const request = request_store.find(dateClickArg.date)
  if (event) {
    if (request && request.status !== 'refused') {
      const toast = useToast()
      toast.error('Impossible de modifier la disponibilité: Une intervention est présente')
      return
    }

    availability_store.remove(getUserId(), dateClickArg.date)
  } else {
    availability_store.create(getUserId(), dateClickArg.date)
  }
}
</script>

<style scoped>
.availability-calendar-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.recurrence {
  display: flex;
  gap: 20px;
}

.availability-calendar-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.availability-calendar-title {
  text-transform: capitalize;
  display: flex;
  color: var(--vc-dark);
  gap: 10px;
}

.actions-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fc-availability-requests {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.fc-availability-holyday {
  position: absolute;
  right: 5px;
  top: 5px;
}

.fc-availability-holyday > .fc-availability-holyday-name {
  pointer-events: none;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
}

.fc-availability-holyday:hover > .fc-availability-holyday-name {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  border-radius: 5px;
  opacity: 1;
  background-color: var(--vc-dark);
  color: white;
  padding: 5px;
  bottom: -30px;
  right: 0;
}
</style>

<style>
.fc-availability-header {
  background-color: rgba(var(--vcrgb-dark), 0.2);
  text-transform: capitalize;
  color: var(--vc-dark) !important;
  font-family: Roboto !important;
  text-align: left !important;
  padding: 12px !important;
  font-size: 16px !important;
}

.fc-availability-cell.fc-day-today:after {
  background-color: rgba(var(--vcrgb-primary), 0.8);
  position: absolute;
  z-index: 1;
  content: '';
  height: 4px;
  width: 100%;
  top: 0;
  left: 0;
}

.fc-availability-cell {
  transition: cubic-bezier(0.19, 1, 0.22, 1) 600ms;
  box-shadow: 0 0 0 1px transparent inset;
  background: none !important;
  cursor: pointer;
}

.fc-availability-cell:hover {
  transition: cubic-bezier(0.19, 1, 0.22, 1) 600ms;
  box-shadow: 0 0 0 1px var(--vc-dark) inset;
}

.fc-availability-cell > .fc-daygrid-day-frame > .fc-daygrid-day-top {
  flex-direction: row !important;
  font-size: 21px !important;
  padding: 12px !important;
}

.fc-availability-cell > .fc-daygrid-day-frame > .fc-daygrid-day-events {
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fc-availability-cell > .fc-daygrid-day-frame > .fc-daygrid-day-events > .fc-daygrid-event-harness {
  position: absolute;
  height: 100%;
  width: 100%;
}

.fc-availability-cell
  > .fc-daygrid-day-frame
  > .fc-daygrid-day-events
  > .fc-daygrid-event-harness
  > .fc-availability-event {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 5px;
  border: 0;
  display: flex;
  align-items: end;
  justify-content: right;
  pointer-events: none;
}

.fc-available {
  margin: 0 !important;
  pointer-events: none;
  background-color: rgba(var(--vcrgb-success), 0.2);
  border: 1px solid rgba(var(--vcrgb-success), 0.1);
  border-radius: 0;
  height: 100%;
}

.availability-calendar {
  border-radius: 8px 8px 0px 0px !important;
  overflow: hidden;
}

.fc-day-today {
  position: relative;
}
</style>
