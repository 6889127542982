import { defineStore } from 'pinia'
import type { components } from 'types/api'
import { useGAEAPI } from '@/composable/gae_api'
import type { BillingGETResponse } from 'types/api/billing'
import { handleErrorNotification } from '@/services/service_api'

type BillingStoreState = {
  fetching: boolean,
  billings: components['schemas']['Billing-billing.read'][]
}

export const useBillingStore = defineStore('billing', {
  state: (): BillingStoreState => ({
    fetching: false,
    billings: []
  }),
  getters: {
    getBillings: (state) => state.billings,
  },
  actions: {
    fetchBillings(user_id: number, date: Date) {
      const api = useGAEAPI();
      this.fetching = true;
      api.instance.get<BillingGETResponse>(`/api/consultants/${user_id}/billing`, {
        headers: {
          Accept: "application/json"
        },
        params: {
          month: date.getMonth() + 1,
          year: date.getFullYear()
        }
      }).then(response => {
        this.billings = response.data;
      }).finally(() => {
        this.fetching = false;
      }).catch(handleErrorNotification)
    }
  }
})
