<template>
  <div ref="users" class="composite-consultant-list">
    <ModalConsultant
      v-if="floating_consultant"
      :consultant="floating_consultant"
      ref="floating_modal"
    />
    <InputCommon
      id="consultant-search"
      label="Rechercher un consultant"
      placeholder="Jean Dupont / JD / Formation / FI RPPA ..."
      v-model="search_input"
    />
    <CommonLoader v-if="user_store.fetching" />
    <template v-for="(user, index) in filter(user_store.getConsultants)" :key="user.id">
      <CompositeConsultantCard v-if="index + 1 <= rendered" :consultant="user" />
    </template>
    <div class="list-footer">
      <CommonButton class="mt-30" icon="ArrowRight" @click="() => (rendered += 10)">
        Afficher plus de consultants
      </CommonButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import CompositeConsultantCard from './CompositeConsultantCard.vue'
import { useUserStore } from '@/stores/gae/store_user'
import { nextTick, onMounted, ref } from 'vue'
import InputCommon from '../common/input/InputCommon.vue'
import type { Consultant } from 'types/api/consultant'
import CommonButton from '../common/CommonButton.vue'
import CommonLoader from '../common/CommonLoader.vue'
import { useRoute } from 'vue-router'
import ModalConsultant from './modal/ModalConsultant.vue'

const user_store = useUserStore()
const users = ref<HTMLDivElement>()
const search_input = ref('')
const rendered = ref(10)
const route = useRoute()
const floating_consultant = ref<Consultant | undefined>()
const floating_modal = ref<InstanceType<typeof ModalConsultant>>()

function filter(data: Consultant[]) {
  if (search_input.value.length > 1) {
    const search = search_input.value.toLowerCase()
    return data.filter((user) => {
      if (user.firstname.toLowerCase().includes(search)) {
        return user
      }
      if (user.lastname.toLowerCase().includes(search)) {
        return user
      }
      if (user.acronym.toLowerCase().includes(search)) {
        return user
      }

      if (user.skills) {
        for (let i = 0; i < user.skills.length; i++) {
          const skill = user.skills[i]
          if (
            skill.category.toLowerCase().includes(search) ||
            skill.shortname.toLowerCase().includes(search) ||
            skill.name.toLowerCase().includes(search)
          )
            return user
        }
      }
    })
  }

  return data
}

onMounted(() => {
  user_store
    .fetchAll({
      page: 1,
      deleted: true
    })
    .then(() => {
      if (route.query.u) {
        const id = parseInt(route.query.u as string)
        floating_consultant.value = user_store.getConsultants.find((e) => e.id === id)
        nextTick(() => {
          floating_modal.value?.open()
        })
      }
    })
})
</script>

<style scoped>
.list-footer {
  display: flex;
  justify-content: end;
}
</style>
