export type Region = {
    code: string,
    name: string,
    region: number,
    region_name: string
}

export const FR_REGIONS: Region[] = [
    {
        code: "01",
        name: "Ain",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "02",
        name: "Aisne",
        region: 32,
        region_name: "Hauts-de-France"
    },
    {
        code: "03",
        name: "Allier",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "04",
        name: "Alpes-de-Haute-Provence",
        region: 93,
        region_name: "Provence-Alpes-Côte d'Azur"
    },
    {
        code: "05",
        name: "Hautes-Alpes",
        region: 93,
        region_name: "Provence-Alpes-Côte d'Azur"
    },
    {
        code: "06",
        name: "Alpes-Maritimes",
        region: 93,
        region_name: "Provence-Alpes-Côte d'Azur"
    },
    {
        code: "07",
        name: "Ardèche",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "08",
        name: "Ardennes",
        region: 44,
        region_name: "Grand Est"
    },
    {
        code: "09",
        name: "Ariège",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "10",
        name: "Aube",
        region: 44,
        region_name: "Grand Est"
    },
    {
        code: "11",
        name: "Aude",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "12",
        name: "Aveyron",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "13",
        name: "Bouches-du-Rhône",
        region: 93,
        region_name: "Provence-Alpes-Côte d'Azur"
    },
    {
        code: "14",
        name: "Calvados",
        region: 28,
        region_name: "Normandie"
    },
    {
        code: "15",
        name: "Cantal",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "16",
        name: "Charente",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "17",
        name: "Charente-Maritime",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "18",
        name: "Cher",
        region: 24,
        region_name: "Centre-Val de Loire"
    },
    {
        code: "19",
        name: "Corrèze",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "21",
        name: "Côte-d'Or",
        region: 27,
        region_name: "Bourgogne-Franche-Comté"
    },
    {
        code: "22",
        name: "Côtes-d'Armor",
        region: 53,
        region_name: "Bretagne"
    },
    {
        code: "23",
        name: "Creuse",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "24",
        name: "Dordogne",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "25",
        name: "Doubs",
        region: 27,
        region_name: "Bourgogne-Franche-Comté"
    },
    {
        code: "26",
        name: "Drôme",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "27",
        name: "Eure",
        region: 28,
        region_name: "Normandie"
    },
    {
        code: "28",
        name: "Eure-et-Loir",
        region: 24,
        region_name: "Centre-Val de Loire"
    },
    {
        code: "29",
        name: "Finistère",
        region: 53,
        region_name: "Bretagne"
    },
    {
        code: "2A",
        name: "Corse-du-Sud",
        region: 94,
        region_name: "Corse"
    },
    {
        code: "2B",
        name: "Haute-Corse",
        region: 94,
        region_name: "Corse"
    },
    {
        code: "30",
        name: "Gard",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "31",
        name: "Haute-Garonne",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "32",
        name: "Gers",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "33",
        name: "Gironde",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "34",
        name: "Hérault",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "35",
        name: "Ille-et-Vilaine",
        region: 53,
        region_name: "Bretagne"
    },
    {
        code: "36",
        name: "Indre",
        region: 24,
        region_name: "Centre-Val de Loire"
    },
    {
        code: "37",
        name: "Indre-et-Loire",
        region: 24,
        region_name: "Centre-Val de Loire"
    },
    {
        code: "38",
        name: "Isère",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "39",
        name: "Jura",
        region: 27,
        region_name: "Bourgogne-Franche-Comté"
    },
    {
        code: "40",
        name: "Landes",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "41",
        name: "Loir-et-Cher",
        region: 24,
        region_name: "Centre-Val de Loire"
    },
    {
        code: "42",
        name: "Loire",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "43",
        name: "Haute-Loire",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "44",
        name: "Loire-Atlantique",
        region: 52,
        region_name: "Pays de la Loire"
    },
    {
        code: "45",
        name: "Loiret",
        region: 24,
        region_name: "Centre-Val de Loire"
    },
    {
        code: "46",
        name: "Lot",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "47",
        name: "Lot-et-Garonne",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "48",
        name: "Lozère",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "49",
        name: "Maine-et-Loire",
        region: 52,
        region_name: "Pays de la Loire"
    },
    {
        code: "50",
        name: "Manche",
        region: 28,
        region_name: "Normandie"
    },
    {
        code: "51",
        name: "Marne",
        region: 44,
        region_name: "Grand Est"
    },
    {
        code: "52",
        name: "Haute-Marne",
        region: 44,
        region_name: "Grand Est"
    },
    {
        code: "53",
        name: "Mayenne",
        region: 52,
        region_name: "Pays de la Loire"
    },
    {
        code: "54",
        name: "Meurthe-et-Moselle",
        region: 44,
        region_name: "Grand Est"
    },
    {
        code: "55",
        name: "Meuse",
        region: 44,
        region_name: "Grand Est"
    },
    {
        code: "56",
        name: "Morbihan",
        region: 53,
        region_name: "Bretagne"
    },
    {
        code: "57",
        name: "Moselle",
        region: 44,
        region_name: "Grand Est"
    },
    {
        code: "58",
        name: "Nièvre",
        region: 27,
        region_name: "Bourgogne-Franche-Comté"
    },
    {
        code: "59",
        name: "Nord",
        region: 32,
        region_name: "Hauts-de-France"
    },
    {
        code: "60",
        name: "Oise",
        region: 32,
        region_name: "Hauts-de-France"
    },
    {
        code: "61",
        name: "Orne",
        region: 28,
        region_name: "Normandie"
    },
    {
        code: "62",
        name: "Pas-de-Calais",
        region: 32,
        region_name: "Hauts-de-France"
    },
    {
        code: "63",
        name: "Puy-de-Dôme",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "64",
        name: "Pyrénées-Atlantiques",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "65",
        name: "Hautes-Pyrénées",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "66",
        name: "Pyrénées-Orientales",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "67",
        name: "Bas-Rhin",
        region: 44,
        region_name: "Grand Est"
    },
    {
        code: "68",
        name: "Haut-Rhin",
        region: 44,
        region_name: "Grand Est"
    },
    {
        code: "69",
        name: "Rhône",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "70",
        name: "Haute-Saône",
        region: 27,
        region_name: "Bourgogne-Franche-Comté"
    },
    {
        code: "71",
        name: "Saône-et-Loire",
        region: 27,
        region_name: "Bourgogne-Franche-Comté"
    },
    {
        code: "72",
        name: "Sarthe",
        region: 52,
        region_name: "Pays de la Loire"
    },
    {
        code: "73",
        name: "Savoie",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "74",
        name: "Haute-Savoie",
        region: 84,
        region_name: "Auvergne-Rhône-Alpes"
    },
    {
        code: "75",
        name: "Paris",
        region: 11,
        region_name: "Île-de-France"
    },
    {
        code: "76",
        name: "Seine-Maritime",
        region: 28,
        region_name: "Normandie"
    },
    {
        code: "77",
        name: "Seine-et-Marne",
        region: 11,
        region_name: "Île-de-France"
    },
    {
        code: "78",
        name: "Yvelines",
        region: 11,
        region_name: "Île-de-France"
    },
    {
        code: "79",
        name: "Deux-Sèvres",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "80",
        name: "Somme",
        region: 32,
        region_name: "Hauts-de-France"
    },
    {
        code: "81",
        name: "Tarn",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "82",
        name: "Tarn-et-Garonne",
        region: 76,
        region_name: "Occitanie"
    },
    {
        code: "83",
        name: "Var",
        region: 93,
        region_name: "Provence-Alpes-Côte d'Azur"
    },
    {
        code: "84",
        name: "Vaucluse",
        region: 93,
        region_name: "Provence-Alpes-Côte d'Azur"
    },
    {
        code: "85",
        name: "Vendée",
        region: 52,
        region_name: "Pays de la Loire"
    },
    {
        code: "86",
        name: "Vienne",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "87",
        name: "Haute-Vienne",
        region: 75,
        region_name: "Nouvelle-Aquitaine"
    },
    {
        code: "88",
        name: "Vosges",
        region: 44,
        region_name: "Grand Est"
    },
    {
        code: "89",
        name: "Yonne",
        region: 27,
        region_name: "Bourgogne-Franche-Comté"
    },
    {
        code: "90",
        name: "Territoire de Belfort",
        region: 27,
        region_name: "Bourgogne-Franche-Comté"
    },
    {
        code: "91",
        name: "Essonne",
        region: 11,
        region_name: "Île-de-France"
    },
    {
        code: "92",
        name: "Hauts-de-Seine",
        region: 11,
        region_name: "Île-de-France"
    },
    {
        code: "93",
        name: "Seine-Saint-Denis",
        region: 11,
        region_name: "Île-de-France"
    },
    {
        code: "94",
        name: "Val-de-Marne",
        region: 11,
        region_name: "Île-de-France"
    },
    {
        code: "95",
        name: "Val-d'Oise",
        region: 11,
        region_name: "Île-de-France"
    },
    {
        code: "971",
        name: "Guadeloupe",
        region: 1,
        region_name: "Guadeloupe"
    },
    {
        code: "972",
        name: "Martinique",
        region: 2,
        region_name: "Martinique"
    },
    {
        code: "973",
        name: "Guyane",
        region: 3,
        region_name: "Guyane"
    },
    {
        code: "974",
        name: "La Réunion",
        region: 4,
        region_name: "La Réunion"
    },
    {
        code: "976",
        name: "Mayotte",
        region: 6,
        region_name: "Mayotte"
    }
]