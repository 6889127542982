import type { Intervention } from "types/api/intervention";
import { makeAddressString } from "./service_date";
import { getInterventionDateTime } from "./service_intervention";

type GoogleCalendarEventOptions = {
    title: string,
    description: string,
    location: string,
    start?: Date,
    end?: Date
}

function parse_date(date: Date) {

    let str = date.toISOString();
    const [d, t] = str.split('T');
    const [h, m] = t.split(':');
    const date_str = d.replace(/\-/g, "");
    const time = `${h}${m}00`;
    return `${date_str}T${time}Z`;
}

function parse_time(start: Date, end: Date): string {
    return `${parse_date(start)}/${parse_date(end)}`
}

export function generate_calendar_event_from_intervention(intervention: Intervention): GoogleCalendarEventOptions {
    return {
        title: `GAE - ${intervention.title} - ${intervention.customer}`,
        description: intervention.comment ?? "",
        location: makeAddressString(intervention.address, intervention.postcode, intervention.city),
        start: getInterventionDateTime(intervention.date, intervention.startTime),
        end: getInterventionDateTime(intervention.date, intervention.endTime),
    }

}

//https://www.google.com/calendar/render?action=TEMPLATE&text=test&details=test&location=test&dates=20240122T135500Z%2F20240123T135500Z
export function generate_calendar_event(options: GoogleCalendarEventOptions): string {
    const url = new URL("https://www.google.com/calendar/render");
    url.searchParams.append("action", "TEMPLATE")
    url.searchParams.append("text", options.title)
    url.searchParams.append("details", options.description)
    url.searchParams.append("location", options.location)
    if (options.start && options.end)
        url.searchParams.append("dates", parse_time(options.start, options.end))

    return url.toString();
}

export function generate_ical_event(options: GoogleCalendarEventOptions): void {
    const values: string[][] = [];
    values.push(['BEGIN', 'VCALENDAR']);
    values.push(['VERSION', '2.0']);
    values.push(['PRODID', '-//xyz Corp//NONSGML PDA Calendar Version 1.0//EN']);

    values.push(['BEGIN', 'VEVENT']);
    if (options.start && options.end) {
        values.push(['DTSTART', parse_date(options.start)]);
        values.push(['DTEND', parse_date(options.end)]);
    }

    values.push(['SUMMARY', options.title]);
    values.push(['DESCRIPTION', options.description]);
    values.push(['LOCATION', options.location]);
    values.push(['END', 'VEVENT']);

    values.push(['END', 'VCALENDAR']);

    const csv = `data:text/calendar;charset=utf-8,${values.map(e => e.join(':')).join('\n')}`

    const encode = encodeURI(csv);
    const link = document.createElement('a');
    link.setAttribute('href', encode)
    link.setAttribute('download', `${options.title}.ics`)
    const node = document.body.appendChild(link);
    link.click();

    document.body.removeChild(node)
}