import './assets/main.css'
import './assets/toast.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ToastPlugin from 'vue-toast-notification'
import * as Sentry from "@sentry/vue"

import App from './App.vue'
import router from './router'

const app = createApp(App)

if (import.meta.env.MODE === 'production') {
  // Sentry.init({
  //   dsn: "https://2f6d19d459f9f3cec7c2b135b9708224@o4506852061937664.ingest.us.sentry.io/4506852062920704",
  //   integrations: [
  //     Sentry.browserTracingIntegration(),
  //     Sentry.replayIntegration({
  //       maskAllText: false,
  //       blockAllMedia: false,
  //     }),
  //   ],
  //   app,
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // })
}

app.use(ToastPlugin, {
  position: 'top'
})
app.use(createPinia())
app.use(router)

app.mount('#app')
