<template>
  <CommonHeader title="Mes missions" />
  <LayoutContainer :max-width="1040">
    <h3 class="mb-20">Vos interventions à venir</h3>
    <div class="request-list mb-40">
      <div class="no-data" v-if="request_store.getAcceptedRequests.length == 0">
        <Info />
        Aucune intervention à venir.
      </div>
      <template v-for="request in request_store.getAcceptedRequests" :key="request.id">
        <CompositeInterventionCard
          :id="`request-${request.id}`"
          v-if="request && request.status === 'accepted'"
          :intervention="request.intervention"
          :request="request"
        />
      </template>
    </div>
    <h3 class="mb-20">Vos interventions à valider</h3>
    <div class="request-list mb-40">
      <div class="no-data" v-if="request_store.getPendingRequests.length == 0">
        <Info />
        Aucune demande en attente de validation.
      </div>
      <template v-for="(request, index) in request_store.getPendingRequests" :key="request.id">
        <CompositeInterventionCard
          v-if="index + 1 <= rendered"
          :id="`request-${request.id}`"
          :intervention="request.intervention"
          :request="request"
        />
      </template>
      <div class="list-footer">
        <CommonButton
          class="mt-30"
          icon="ArrowRight"
          @click="() => (rendered += 5)"
          v-if="rendered <= request_store.getPendingRequests.length"
        >
          Afficher plus d'interventions
        </CommonButton>
      </div>
    </div>
    <h3 class="mb-20">Vos interventions en attente de documents</h3>
    <div class="request-list mb-40">
      <div class="no-data" v-if="request_store.getPendingDocumentIntervention.length == 0">
        <Info />
        Aucune intervention en attente de documents.
      </div>
      <template v-for="request in request_store.getPendingDocumentIntervention" :key="request.id">
        <CompositeInterventionCard
          :id="`request-${request.id}`"
          v-if="request && request.status === 'accepted'"
          :intervention="request.intervention"
          :request="request"
        />
      </template>
    </div>
    <h3 class="mb-20">Vos interventions passées</h3>
    <div class="request-list mb-40">
      <div class="no-data" v-if="request_store.getPassedInterventions.length == 0">
        <Info />
        Aucune intervention passée.
      </div>
      <template v-for="request in request_store.getPassedInterventions" :key="request.id">
        <CompositeInterventionCard
          :id="`request-${request.id}`"
          v-if="request && request.status === 'accepted'"
          :intervention="request.intervention"
          :request="request"
        />
      </template>
    </div>
  </LayoutContainer>
</template>

<script setup lang="ts">
import CommonHeader from '@/components/common/CommonHeader.vue'
import CommonButton from '@/components/common/CommonButton.vue'
import CompositeInterventionCard from '@/components/composite/CompositeInterventionCard.vue'
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import { useRequestStore } from '@/stores/gae/store_requests'
import { Info } from 'lucide-vue-next'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const request_store = useRequestStore()
const route = useRoute()
const rendered = ref(5)

onMounted(() => {
  request_store.fetchRequests().then(() => {
    if (route.query.id) {
      const element = document.getElementById(`request-${route.query.id}`)
      element?.click()
    }
  })
})
</script>

<style scoped>
.request-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.no-data {
  color: rgba(var(--vcrgb-dark), 0.6);
  display: flex;
  align-items: center;
  gap: 10px;
}

.list-footer {
  display: flex;
  justify-content: end;
}
</style>
