<template>
  <form>
    <LayoutTabs>
      <template #informations>
        <div class="form-spacer mb-40">
          <LayoutTwoColumn>
            <InputCommon
              id="consultant-lastname"
              label="Nom"
              placeholder="Thomas"
              required
              v-model="v$.lastname.$model"
              :errors="v$.lastname.$errors"
            />
            <InputCommon
              id="consultant-firstname"
              label="Prénom"
              placeholder="Muller"
              required
              v-model="v$.firstname.$model"
              :errors="v$.firstname.$errors"
            />
            <InputCommon
              id="consultant-email"
              label="Email"
              placeholder="muller.thomas@mail.com"
              required
              v-model="v$.email.$model"
              :errors="v$.email.$errors"
            />
            <InputCommon
              id="consultant-reference"
              label="Matricule"
              placeholder="TM"
              required
              v-model="v$.acronym.$model"
              :errors="v$.acronym.$errors"
            />
          </LayoutTwoColumn>
          <InputSkills
            id="consultant-skills"
            label="Catégorie de prestation"
            required
            v-model="form.skills"
            multiple
          />
          <InputCommon
            id="intervention-address"
            label="Adresse"
            placeholder="3 Rue de la Division Leclerc"
            v-model="form.address"
          />
          <LayoutTwoColumn>
            <InputCommon
              id="intervention-postcode"
              label="CP"
              placeholder="67000"
              v-model="form.postcode"
            />
            <InputCommon
              id="intervention-city"
              label="Ville"
              placeholder="Strasbourg"
              v-model="form.city"
            />
            <InputCommon
              id="intervention-job"
              label="Fonction"
              placeholder="Enseignant"
              v-model="form.job"
            />
            <InputCommon
              id="intervention-phone"
              label="Téléphone"
              placeholder="06 12 34 56 78"
              v-model="form.phone"
            />
            <InputCommon
              id="onedrive-link"
              label="Lien OneDrive"
              placeholder="https://www.onedrive.com/"
              v-model="form.onedrive"
            />
          </LayoutTwoColumn>
          <InputCheckbox
            id="consultant-availability"
            label="Préférences de disponibilités"
            v-model="v$.availability.$model"
            :errors="v$.availability.$errors"
            :options="[
              { label: 'Journée', value: 'day' },
              { label: 'Nuit', value: 'night' },
              { label: 'Week-end', value: 'weekend' }
            ]"
          />
        </div>
        <CommonParagraph title="Informations supplémentaires">
          <InputBoolean id="consultant-expert" label="Patient expert" v-model="form.expert" />
        </CommonParagraph>
      </template>
      <template #zones>
        <CompositeZones v-model="form.zones" />
      </template>
    </LayoutTabs>
  </form>
</template>

<script setup lang="ts">
import CommonParagraph from '@/components/common/CommonParagraph.vue'
import InputBoolean from '@/components/common/input/InputBoolean.vue'
import InputCheckbox from '@/components/common/input/InputCheckbox.vue'
import InputCommon from '@/components/common/input/InputCommon.vue'
import InputSkills from '@/components/common/input/InputSkills.vue'
import LayoutTabs from '@/components/layout/LayoutTabs.vue'
import LayoutTwoColumn from '@/components/layout/LayoutTwoColumn.vue'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import type { Consultant, ConsultantForm } from 'types/api/consultant'
import { reactive, ref } from 'vue'
import CompositeZones from '../CompositeZones.vue'

function getValidation() {
  return v$.value.$validate()
}

function getData() {
  return form
}

function setData(data: ConsultantForm) {
  Object.assign(form, data)
}

function load(consultant: Consultant) {
  setData({
    firstname: consultant.firstname,
    lastname: consultant.lastname,
    email: consultant.email,
    acronym: consultant.acronym,
    availability: consultant.availability,
    skills: consultant.skills,
    expert: consultant.is_expert_patient,
    phone: consultant.phone as string | undefined,
    address: consultant.address as string | undefined,
    postcode: consultant.postcode as string | undefined,
    city: consultant.city as string | undefined,
    job: consultant.job as string | undefined,
    onedrive: consultant.onedrive as string | undefined,
    zones: {
      '1': consultant.consultantDepartements[1]?.join(',') ?? '',
      '2': consultant.consultantDepartements[2]?.join(',') ?? '',
      '3': consultant.consultantDepartements[3]?.join(',') ?? '',
      '4': consultant.consultantDepartements[4]?.join(',') ?? '',
      '5': consultant.consultantDepartements[5]?.join(',') ?? ''
    }
  })
}

defineExpose({ getData, setData, load, getValidation })

const form = reactive<ConsultantForm>({
  firstname: '',
  lastname: '',
  email: '',
  acronym: '',
  availability: [],
  skills: [],
  expert: false,
  address: '',
  postcode: '',
  city: '',
  phone: '',
  job: '',
  onedrive: '',
  zones: {
    '1': '',
    '2': '',
    '3': '',
    '4': '',
    '5': ''
  }
})

const rules = {
  firstname: {
    required: helpers.withMessage('Le champ est requis.', required)
  },
  lastname: {
    required: helpers.withMessage('Le champ est requis.', required)
  },
  email: {
    required: helpers.withMessage('Le champ est requis.', required)
  },
  acronym: {
    required: helpers.withMessage('Le champ est requis.', required)
  },
  availability: {
    required: helpers.withMessage('Veuillez indiquer au moins une valeur', (e: []) => e.length > 0)
  }
}

const v$ = useVuelidate(rules, form as any)
</script>
