<template>
  <div class="input-datepicker-wrapper">
    <label :for="id">{{ label }}{{ required ? '*' : '' }} :</label>
    <div class="input-datepicker" :class="{ error: errors && errors.length > 0 }">
      <CommonIcon
        :class="{ 'has-value': input != undefined }"
        class="common-button-icon"
        name="Clock"
        :stroke-width="1.5"
        :size="16"
      />
      <DatePicker
        :id="id"
        input-class-name="datepicker-input"
        v-model="input"
        :placeholder="placeholder"
        time-picker
        hide-input-icon
        select-text="Valider"
        cancel-text="Annuler"
        auto-apply
        :text-input="textInputOptions"
      />
    </div>
    <div v-if="errors && errors.length > 0" class="input-datepicker__error">
      <AlertCircle :size="16" />
      <p>{{ errors[0].$message }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import DatePicker, { type TimeModel } from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import CommonIcon from '@/components/common/CommonIcon.vue'
import { computed } from 'vue'
import type { ErrorObject } from '@vuelidate/core'
import { AlertCircle } from 'lucide-vue-next'

const emits = defineEmits(['update:modelValue'])

function validateTimeFormat(inputStr: string): Date | null {
  const pattern = /^([01]?[0-9]|2[0-4])[h:](0?[0-9]|[1-5][0-9])$/
  const match = inputStr.match(pattern)
  if (match) {
    const [, hours, minutes] = match
    const parsedHours = hours ? parseInt(hours, 10) : 0
    const parsedMinutes = parseInt(minutes, 10)

    const date = new Date()
    date.setHours(parsedHours)
    date.setMinutes(parsedMinutes)

    return date
  } else {
    return null
  }
}

const textInputOptions = {
  format: validateTimeFormat,
  openMenu: false
}

const props = defineProps<{
  id: string
  label: string
  modelValue?: TimeModel
  placeholder?: string
  required?: boolean
  errors?: ErrorObject[]
}>()

const input = computed({
  get: () => {
    return props.modelValue
  },
  set: (v?: TimeModel): void => {
    emits('update:modelValue', v)
  }
})
</script>

<style scoped lang="scss">
.input-datepicker-wrapper > label {
  display: block;
  margin-bottom: 15px;
}

.input-datepicker {
  position: relative;

  &__error {
    color: var(--vc-danger);
    font-size: 12px;
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 5px;
  }
}
.common-button-icon {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 24px;
  height: 24px;
  color: var(--vc-dark);
  position: absolute;
  z-index: 10;
  right: 30px;
  top: calc(50% - 12px);
}

.has-value {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  right: 45px;
}

:global(:root) {
  --dp-input-padding: 20px 20px; /*Padding in the input*/
  --dp-border-radius: 5px;
}

.error {
  border: 1px solid var(--vc-danger) !important;
  border-radius: 5px;
}
</style>

<style>
.datepicker-input {
  font-size: 14px;
  color: var(--vc-dark);
}
</style>
