import { defineStore } from 'pinia'
import type { APIClickedLinkPOSTRequest, MediaObjectDTO } from 'types/api/file'
import type { Consultant } from 'types/api/consultant'
import { useGAEAPI } from '@/composable/gae_api'
import { handleErrorNotification } from '@/services/service_api'
import type { InterventionDocumentRequest, InterventionDocumentResponse, MediaObjectPOSTResponse, APIClickedLinkGETResponse, APIClickedLinkPOSTResponse, ClickedLink } from 'types/api/file'
import type { AxiosResponse } from 'axios'
import { useInterventionStore } from './store_intervention'
import type { Intervention, InterventionDoneDocument, InterventionDoneDocumentRequest } from 'types/api/intervention'
import { useToast } from 'vue-toast-notification'
import { useRequestStore } from './store_requests'
import { useSecurityStore } from './store_security'

type AppDataStoreState = {
  fetching: boolean
  clickedLinks: ClickedLink[]
}
type FileData = {
  id: number
  name: string
  url: string
  size: number
  mime: string
  created_at: string
}

export const useFile = defineStore('file', {
  state: (): AppDataStoreState => ({
    fetching: false,
    clickedLinks: []
  }),
  getters: {
    isFetching: (state) => state.fetching,
    getClickedLinks: (state) => state.clickedLinks,
  },
  actions: {
    async validate(intervention: Intervention) {
      const intervention_store = useInterventionStore();
      const links = this.getInterventionClickedLinks(intervention.id);
      if (links.length !== 0) {
        links.forEach((link) => {
          this.deleteClickedLinks(link.id)
        })
      }
      return intervention_store.validate(intervention);
    },
    async fetchInterventionDocuments(id: number) {
      const api = useGAEAPI();
      return api.instance.get(`/api/intervention_done_documents_archive/${id}`);
    },
    async uploadFile(file: File) {

      const form = new FormData()

      form.append('file', file)

      const api = useGAEAPI()
      this.fetching = true;
      return api.instance.post<MediaObjectPOSTResponse>(`/api/media_objects`, form).finally(() => {
        this.fetching = false;
      })
    },
    async uploadInterventionDocument(id: number, media_object: MediaObjectDTO, category: number) {
      const api = useGAEAPI()
      this.fetching = true;
      return api.instance.post<InterventionDoneDocument, any, InterventionDoneDocumentRequest>(`/api/interventions/${id}/documents`, {
        categoryId: category,
        mediaObjectId: media_object.id,
        link: null,
      }).catch(handleErrorNotification).finally(() => this.fetching = false)
    },
    async uploadInterventionLink(id: number, link: string, category: number) {
      const api = useGAEAPI()
      this.fetching = true;
      return api.instance.post<InterventionDoneDocument, any, InterventionDoneDocumentRequest>(`/api/interventions/${id}/documents`, {
        categoryId: category,
        mediaObjectId: null,
        link: link,
      }, { headers: { Accept: 'application/json' } }).catch(handleErrorNotification).finally(() => this.fetching = false)
    },
    async deleteFile(id: number) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance.delete(`/api/media_objects/${id}`).catch(handleErrorNotification).finally(() => this.fetching = false)
    },
    async deleteInterventionDocument(id: number) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance.delete(`/api/documents/${id}`).catch(handleErrorNotification).finally(() => this.fetching = false)
    },
    async confirmInterventionDocument(id: number) {
      const api = useGAEAPI()
      this.fetching = true;
      return api.instance.post(`/api/interventions/${id}/confirm_documents`)
        .then(() => {
          const toast = useToast()
          toast.success("Vos documents ont été transmis.");
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.fetching = false;
        })
    },
    async sendInterventionDocuments(intervention: Intervention) {
      const api = useGAEAPI();
      this.fetching = true;
      return api.instance.post(`/api/interventions/${intervention.id}/documents/confirm`)
        .then(() => {
          const toast = useToast()
          const request_store = useRequestStore();
          const security_store = useSecurityStore();
          const intervention_store = useInterventionStore()

          intervention.missionStatus = "documents_uploaded";
          if (security_store.isAdmin) {
            intervention_store.updateIntervention(intervention)
          }
          request_store.updateInterventions(intervention)
          toast.success("Vos documents ont été envoyés.");
        }).catch(handleErrorNotification).finally(() => {
          this.fetching = false;
        })
    },
    async addClickedLink(clickedLink: APIClickedLinkPOSTRequest) {
      const api = useGAEAPI()
      return api.instance.post<APIClickedLinkPOSTResponse>(`/api/clicked_links`, clickedLink)
        .then((response) => {
          this.clickedLinks.push(response.data)
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.fetching = false
        })
    },
    async fetchClickedLinks(user: number) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance.get(`/api/consultants/${user}/clicked_links`, {
        headers: {
          Accept: 'application/json'
        }
      })
        .then((response) => {
          this.clickedLinks = response.data
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.fetching = false
        })
    },
    getInterventionClickedLinks(interventionId: number) {
      return this.getClickedLinks.filter((link) => link.intervention_id === interventionId)
    },
    async deleteClickedLinks(id: number) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance
        .delete(`/api/clicked_links/${id}`, {
          headers: {
            Accept: 'application/json'
          }
        })
        .then((response) => {
          const link_index = this.clickedLinks.findIndex(l => l.id === id);
          if (link_index !== undefined) {
            this.clickedLinks.splice(link_index, 1);
          }
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.fetching = false
        })
    },
  }
})
