<template>
  <div class="stat-chip-container" :class="{ 'flex-column': legend, 'flex-row': !legend }">
    <p v-if="title">{{ title }} :</p>
    <div
      class="common-stat-chip"
      :class="[
        size,
        color,
        filled ? 'filled' : '',
        transparent ? 'transparent' : '',
        extend ? 'extend' : '',
        arrow == 'up' ? 'danger' : ''
      ]"
    >
      <template v-if="arrow">
        <CommonIcon class="icon" :name="getTrend(arrow)" :size="size == 'small' ? 16 : 30" />
      </template>
      <slot />
    </div>
    <p class="legend" v-if="legend">{{ legend }}</p>
  </div>
</template>

<script setup lang="ts">
import CommonIcon, { type Icons } from './CommonIcon.vue'

withDefaults(
  defineProps<{
    title?: string
    color?: ApplicationColors
    size?: 'small' | 'medium' | 'large'
    arrow?: 'up' | 'down' | 'equal'
    filled?: boolean
    extend?: boolean
    transparent?: boolean
    legend?: string
  }>(),
  {
    color: 'primary',
    size: 'small'
  }
)

function getTrend(arrow: 'up' | 'down' | 'equal') {
  const trend = {
    up: 'TrendingUp',
    down: 'TrendingDown',
    equal: 'MoveRight'
  }
  return trend[arrow] as Icons
}
</script>

<style scoped lang="scss">
.stat-chip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.common-stat-chip {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid;
  background-color: transparent;
  cursor: default;
}

.legend {
  color: var(--vc-dark);
  opacity: 75%;
  margin-top: 12px;
  text-align: center;
  max-width: 200px;
}

.small {
  padding: 5px 10px;
  font-size: 14px;
}

.medium {
  padding: 7px 12px;
  font-size: 24px;
}

.large {
  padding: 15px 25px;
  font-size: 34px;
  font-weight: 900;
}

.filled {
  border: 0;
}

.stat-chip-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stat-chip-container > p {
  font-size: 16px;
  font-weight: 500;
  color: var(--vc-dark);
}

.flex-column {
  flex-direction: column;
  width: 100%;
}

.flex-row {
  flex-direction: row;
  width: 100%;
}

.extend {
  width: 100%;
  padding: 10px 0px;
}

@mixin color($class: primary) {
  $background: var(--vc-#{$class});
  $background-rgb: var(--vcrgb-#{$class});

  .#{$class} {
    color: $background;
    border-color: $background;
  }

  .#{$class}.filled {
    background-color: $background;
    color: var(--vc-white);
  }

  .#{$class}.transparent {
    background-color: rgba($background-rgb, 0.1);
    color: $background;
    border: 0;
  }
}

@include color();
@include color(dark);
@include color(success);
@include color(warning);
@include color(danger);
</style>
