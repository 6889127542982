<template>
  <Teleport to="body">
    <div v-if="active" class="layout-modal-message-wrapper" :class="{ open: is_open }">
      <div ref="content" class="layout-modal-message">
        <h4>Confirmer l'action ?</h4>
        <slot />
        <div class="actions">
          <CommonButton filled color="primary" @click="confirm">Confirmer</CommonButton>
          <CommonButton color="dark" @click="close">Annuler</CommonButton>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { nextTick, ref } from 'vue'
import CommonButton from '../common/CommonButton.vue'

const is_open = ref(false)
const active = ref(false)
const content = ref<HTMLElement>()

defineProps<{
  modelValue?: boolean
}>()

function confirm() {
  emits('confirm')
  close()
}

async function open() {
  document.body.style.overflowY = 'hidden'
  active.value = true
  await nextTick()
  is_open.value = true
  emits('update:modelValue', true)
}

async function close() {
  document.body.style.overflowY = ''
  is_open.value = false
  active.value = false
  setTimeout(() => {
    emits('update:modelValue', false)
  }, 50)
}

onClickOutside(content, () => {
  close()
})

defineExpose({ open, close, is_open })
const emits = defineEmits(['confirm', 'onClosed', 'update:modelValue'])
</script>

<style scoped>
.layout-modal-message-wrapper {
  transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background-color: rgba(var(--vcrgb-dark), 0.8);
  pointer-events: none;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-modal-message-wrapper.open {
  transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 1;
  pointer-events: all;
  overflow-y: hidden;
}

.layout-modal-message {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  box-sizing: border-box;
  max-width: 400px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.layout-modal-message-wrapper.open > .layout-modal-message {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: var(--vc-white);
  overflow-y: scroll;
}

@media screen and (max-width: 1024px) {
  .layout-modal-message {
    width: 100%;
  }
}

.actions {
  display: flex;
  gap: 10px;
  justify-content: end;
}
</style>
