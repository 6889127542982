import type { AxiosError } from 'axios'
import axios from 'axios'
import type { components } from 'types/api'
import { useToast, type ToastProps } from 'vue-toast-notification'

type Exception = components['schemas']['Exception']
type ArgumentException = components['schemas']['ArgumentException']
type APIException = {
	code: number
	exception: Exception | ArgumentException
}

export function handleErrorNotification(error: Error | AxiosError) {
	let error_message = ''

	const toast = useToast()
	const error_options: ToastProps = {
		duration: 5000
	}

	if (!axios.isAxiosError(error)) {
		error_message = error.message
	} else {
		switch (error.response?.status) {
			default:
				if (error.response?.data.title) {
					error_message = error.response.data.detail
				} else {
					if (error.response?.data instanceof Blob) {
						error.response?.data.text().then((text) => {
							const exception = JSON.parse(text)
							error_message = exception.message
						})
					} else {
						const exception = error.response?.data as Exception
						error_message = exception.message
					}
				}
				break

			case 422:
				const argument_exception = error.response?.data as ArgumentException
				if (Array.isArray(argument_exception.violations)) {
					error_message = `Des champs sont incorrects: ${argument_exception.violations
						?.map((v) => v.property)
						.join(', ')}`
				}
				else if (argument_exception.violations == null) {
					error_message = 'Une erreur s\'est produite.'
				}
				else {
					error_message = (argument_exception.violations as any).detail
				}
				break
		}
	}
	if (error_message === '' || error_message === undefined) {
		toast.error("Une erreur s'est produite", error_options)
	} else {
		toast.error(error_message, error_options)
	}

	throw error
}

export function ValueOrUndefined<T>(value: T | undefined): T | undefined {
	if (value === null) return undefined

	if (typeof value === 'string' && value === '') {
		return undefined
	}

	if (value === undefined) {
		return undefined
	}
	return value
}

export function NullIfEmpty(value?: string): string | null {
	if (!value || value === '') {
		return null
	}
	return value
}
