<script setup lang="ts">
import { RouterView } from 'vue-router'
import { useSecurityStore } from './stores/gae/store_security'
import { onMounted } from 'vue'
import { useAppData } from './stores/gae/store_appdata'

const security = useSecurityStore()
const appdata = useAppData()
onMounted(() => {
  if (security.is_authenticated) {
    security.fetchMe()
    appdata.fetch()
  }
})
</script>

<template>
  <RouterView />
</template>
