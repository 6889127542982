<template>
    <div class="composite-popup">
        <slot/>
    </div>
</template>

<style scoped>
.composite-popup {
    border: 1px solid rgba(var(--vcrgb-dark), 0.2);
    border-radius: 5px;
    background-color: var(--vc-white);
    padding: 20px;
}
</style>
