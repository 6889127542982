<template>
  <div class="file-wrapper">
    <div class="file-name">
      <CommonIcon name="FileText" class="icon" />
      <a :href="`/api/${downloadUrl}`" class="filename">{{ file }}</a>
      <p class="filesize">({{ formattedSize }})</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonIcon from './CommonIcon.vue'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    file: string
    downloadUrl: string
    size: number
  }>(),
  {}
)

// If file props is too long for the input

// Convert size to o, Ko or Mo
const formattedSize = computed(() => {
  let size = props.size
  if (size < 1024) return `${size} o`
  size /= 1000
  if (size < 1024) return `${Number(size.toFixed(1))} Ko`
  size /= 1000
  return `${Number(size.toFixed(1))} Mo`
})
</script>

<style scoped lang="scss">
.icon {
  color: var(--vc-primary);
}

.file-wrapper {
  border: 1px solid rgba(var(--vcrgb-primary), 0.2);
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}

.file-name {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--vc-dark);
}

.filename:hover {
  text-decoration: underline;
}

.filesize {
  white-space: nowrap;
}
</style>
