<template>
  <div class="input-datepicker-wrapper">
    <label :for="id">{{ label }}{{ required ? '*' : '' }} :</label>
    <div class="input-datepicker" :class="{ error: errors && errors.length > 0 }">
      <CommonIcon
        :class="{ 'has-value': input != null }"
        class="common-button-icon"
        name="Calendar"
        :stroke-width="1.5"
        :size="16"
      />
      <DatePicker
        :id="id"
        input-class-name="datepicker-input"
        v-model="input"
        :placeholder="placeholder"
        hide-input-icon
        locale="fr"
        :enable-time-picker="false"
        :format="format"
        select-text="Valider"
        cancel-text="Annuler"
        auto-apply
        :year-picker="yearPicker"
        :year-range="yearRange"
        
      />
    </div>
    <div v-if="errors && errors.length > 0" class="input-datepicker__error">
      <AlertCircle :size="16" />
      <p>{{ errors[0].$message }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import DatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import CommonIcon from '@/components/common/CommonIcon.vue'
import { computed } from 'vue'
import type { ErrorObject } from '@vuelidate/core'
import { AlertCircle } from 'lucide-vue-next'

const emits = defineEmits(['update:modelValue'])

const props = defineProps<{
  id: string
  label: string
  monthPicker?: boolean
  modelValue?: Date | number
  placeholder?: string
  required?: boolean
  yearPicker?: boolean
  errors?: ErrorObject[]
  yearRange?: number[]
}>()

const input = computed({
  get: () => {
    if (!props.modelValue) return undefined
    if (typeof props.modelValue === 'number') return new Date(props.modelValue)
    return props.modelValue
  },
  set: (v?: Date): void => {
    emits('update:modelValue', v)
  }
})

function format(date: Date): string {
  if (typeof date === 'string') { 
    date = new Date(date)
  }
    
    const options: DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    
    if (props.monthPicker) {
    options.day = undefined
    }

    if (props.yearPicker) {
      options.day = undefined
    options.month = undefined
    }
    
  // Missing option type for typescript.
  // @ts-ignore
  return date.toLocaleDateString('fr-FR', options)
}
</script>

<style lang="scss" scoped>
.input-datepicker-wrapper > label {
  display: block;
  margin-bottom: 15px;
}
.input-datepicker {
  position: relative;
  min-width: 220px;

  &__error {
    color: var(--vc-danger);
    font-size: 12px;
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 5px;
  }
}

.error {
  border: 1px solid var(--vc-danger) !important;
  border-radius: 5px;
}

.common-button-icon {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 24px;
  height: 24px;
  color: var(--vc-dark);
  position: absolute;
  z-index: 10;
  right: 25px;
  top: calc(50% - 12px);
}

.has-value {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  right: 45px;
}

:global(:root) {
  --dp-input-padding: 20px 20px; /*Padding in the input*/
  --dp-border-radius: 5px;
}
</style>

<style>
.datepicker-input {
  font-size: 14px;
  color: var(--vc-dark);
}

.dp__theme_light {
  --dp-primary-color: var(--vc-primary);
}
</style>
