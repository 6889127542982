<template>
  <CommonIconLabel icon="Clock" :size="size">
    {{ getValue() }}
  </CommonIconLabel>
</template>

<script setup lang="ts">
import CommonIconLabel from '@/components/common/CommonIconLabel.vue'
import { i0 } from '@/services/service_date'

const props = withDefaults(
  defineProps<{
    start: Date | string
    end: Date | string
    size?: 'small' | 'medium'
  }>(),
  {
    size: 'small'
  }
)

function getTime(value: Date | string) {
  if (typeof value === 'string') {
    const date = new Date()
    const values = value.split(':')
    date.setHours(parseInt(values[0]), parseInt(values[1]))
    return date
  }
  return value
}

function getValue() {
  const start = getTime(props.start)
  const end = getTime(props.end)

  const sv = `${i0(start.getHours())}:${i0(start.getMinutes())}`
  const ev = `${i0(end.getHours())}:${i0(end.getMinutes())}`

  return `${sv} - ${ev}`
}
</script>
