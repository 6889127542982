<template>
  <CommonIconLabel icon="Calendar" :size="size">
    {{ getValue() }}
  </CommonIconLabel>
</template>

<script setup lang="ts">
import CommonIconLabel from '@/components/common/CommonIconLabel.vue'

const props = withDefaults(
  defineProps<{
    date: Date | string
    size?: 'small' | 'medium'
  }>(),
  {
    size: 'small'
  }
)

function getValue() {
  let date = props.date
  if (typeof date === 'string') {
    date = new Date(date)
  }

  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  // Missing option type for typescript.
  // @ts-ignore
  return date.toLocaleDateString('fr-FR', options)
}
</script>
