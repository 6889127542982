<template>
  <Teleport to="body">
    <div
      ref="wrapper"
      v-if="active"
      class="layout-modal-wrapper"
      :class="{ open: is_open, closing: closing }"
    >
      <div ref="content" class="layout-modal">
        <slot />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/gae/store_user'
import { onClickOutside } from '@vueuse/core'
import { nextTick, ref } from 'vue'

const is_open = ref(false)
const active = ref(false)
const closing = ref(false)
const content = ref<HTMLElement>()
const wrapper = ref()

const emits = defineEmits(['onClosed'])

const props = withDefaults(
  defineProps<{
    canClose?: boolean
  }>(),
  {
    canClose: true
  }
)
const user_store = useUserStore()

async function open() {
  return new Promise<void>((resolve) => {
    document.body.style.overflowY = 'hidden'
    user_store.setBusy(true)
    active.value = true
    nextTick().then(() => {
      is_open.value = true
      resolve()
    })
  })
}

function close(force: boolean = false) {
  if (!force && !props.canClose) return
  document.body.style.overflowY = ''
  closing.value = true
  is_open.value = false
  user_store.setBusy(false)
  emits('onClosed')

  setTimeout(() => {
    active.value = false
    closing.value = false
  }, 550)
}

onClickOutside(content, () => {
  close()
})

defineExpose({ open, close })
</script>

<style scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInWrapper {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes closing {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.layout-modal-wrapper {
  animation: 600ms cubic-bezier(0.19, 1, 0.22, 1) 0s 1 fadeInWrapper;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(var(--vcrgb-dark), 0.6);
  overflow-x: hidden;
  pointer-events: all;
  overflow-y: hidden;
}

.layout-modal-wrapper.open {
  opacity: 1;
}

.layout-modal-wrapper.closing {
  pointer-events: none;
  opacity: 1;
  animation: 600ms cubic-bezier(0.19, 1, 0.22, 1) 0s 1 closing;
}

.layout-modal {
  animation: 600ms cubic-bezier(0.19, 1, 0.22, 1) 0s 1 fadeIn;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  box-sizing: border-box;
  top: 0;
  width: 800px;
  height: 100%;
  background-color: var(--vc-white);
  right: 0;
  overflow-y: scroll;
}

@media screen and (max-width: 1024px) {
  .layout-modal {
    width: 100%;
  }
}
</style>
