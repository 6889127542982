<template>
  <div class="input-boolean" :class="{ error: errors && errors.length > 0 }">
    <label :for="id">{{ label }}{{ required ? '*' : '' }} :</label>
    <section>
      <div>
        <Check class="icon" color="var(--vc-white)" :size="16" />
        <input
          :id="`${id}-true`"
          :name="`${id}-true`"
          type="radio"
          :value="true"
          v-model="input"
          @click="emits('update:modelValue', true)"
        />
        <p>Oui</p>
      </div>
      <div>
        <Check class="icon" color="var(--vc-white)" :size="16" />
        <input
          :id="`${id}-false`"
          :name="`${id}-false`"
          type="radio"
          :value="false"
          v-model="input"
          @click="emits('update:modelValue', false)"
        />
        <p>Non</p>
      </div>
    </section>
    <div v-if="errors && errors.length > 0" class="input-common__error">
      <AlertCircle :size="16" />
      <p>{{ errors[0].$message }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
import { Check } from 'lucide-vue-next'
import { AlertCircle } from 'lucide-vue-next'
import { computed } from 'vue'

const props = defineProps<{
  id: string
  label: string
  modelValue?: boolean
  placeholder?: string
  required?: boolean
  errors?: ErrorObject[]
}>()

const input = computed({
  get: () => {
    return props.modelValue
  },
  set: (v?: boolean): void => {
    emits('update:modelValue', v)
  }
})
const emits = defineEmits<{
  (e: 'update:modelValue', value?: boolean): void
}>()
</script>

<style scoped lang="scss">
input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  background-color: var(--vc-white);
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  cursor: pointer;
}

input[type='radio']:checked {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: var(--vc-primary);
}

.input-boolean {
  display: flex;
  flex-direction: column;
  color: rgba(var(--vcrgb-dark), 0.75);

  &__error {
    color: var(--vc-danger);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.icon {
  position: absolute;
  z-index: 10;
  left: 0;
  width: 20px;
  pointer-events: none;
}

.input-boolean > section {
  display: flex;
  align-items: center;
  gap: 30px;
}

.input-boolean > section > div {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: default;
}

.input-boolean > label {
  margin-bottom: 15px;
}

.input-boolean > input {
  border: 1px solid rgba(var(--vcrgb-black), 0.2);
  background-color: transparent;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 20px 20px;
}

.error > input {
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}
</style>
