<template>
  <img class="logo" :src="LogoGAE" />
  <h1 class="mb-20">Bienvenue sur l’extranet de GAE&nbsp;Conseil.</h1>
  <p class="mb-40">Pour vous connecter, merci de renseigner les champs ci-dessous.</p>
  <form>
    <InputCommon
      v-model="v$.email.$model"
      :errors="v$.email.$errors"
      class="mb-20"
      id="username"
      label="Email"
      required
    />
    <InputCommon
      v-model="v$.password.$model"
      :errors="v$.password.$errors"
      class="mb-10"
      type="password"
      id="password"
      label="Mot de passe"
      required
    />
    <RouterLink to="/password-forgot" class="mb-40 reset-password">
      Mot de passe oublié ?
    </RouterLink>
    <CommonButton class="submit" @click.prevent="login" filled :loading="security.isFetching">
      Se connecter
    </CommonButton>
  </form>
</template>

<script setup lang="ts">
import LogoGAE from '@/assets/images/logo-gae-color.png'
import CommonButton from '@/components/common/CommonButton.vue'
import InputCommon from '@/components/common/input/InputCommon.vue'
import { useSecurityStore } from '@/stores/gae/store_security'
import useVuelidate from '@vuelidate/core'
import { helpers, required, email } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

const security = useSecurityStore()

const form = reactive({
  email: '',
  password: ''
})

const rules = {
  email: {
    required: helpers.withMessage('Veuillez indiquer un email.', required),
    email: helpers.withMessage('Veuillez indiquer un email valide', email)
  },
  password: { required: helpers.withMessage('Veuillez indiquer un mot de passe.', required) }
}

const v$ = useVuelidate(rules, form)

function login() {
  v$.value.$validate().then((valid) => {
    if (valid) {
      security.authenticate(form.email, form.password)
    }
  })
}
</script>

<style scoped>
.logo {
  margin-bottom: 110px;
}

.reset-password {
  width: 100%;
  font-size: 14px;
  color: var(--vc-dark);
  display: inline-block;
  font-style: italic;
  text-align: right;
}

.submit {
  width: 100%;
}
</style>
