<template>
  <RouterLink
    :to="to"
    class="sidebar-item"
    :class="{ selected: to == route.path, tablet: v_tablet, mobile: v_mobile }"
  >
    <CommonIcon :name="icon" :size="v_tablet ? 40 : 20" :stroke-width="1.5" />
    <p v-if="!v_tablet">{{ title }}</p>
  </RouterLink>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import CommonIcon, { type Icons } from './CommonIcon.vue'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'

const breakpoint = useBreakpoints(breakpointsTailwind)
const v_tablet = breakpoint.between('sm', 'lg')
const v_mobile = breakpoint.smallerOrEqual('sm')
const route = useRoute()

defineProps<{
  title: string
  icon: Icons
  to: string
  selected?: boolean
}>()
</script>

<style scoped>
.sidebar-item {
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 30px;
  font-weight: 400;
  font-size: 21px;
  line-height: 23.1px;
  cursor: pointer;
  position: relative;
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-item > p {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 30px;
  width: calc(100% - 60px);
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.selected {
  color: var(--vc-white);
  background-color: rgba(255, 255, 255, 0.1);
}

.selected > p {
  color: var(--vc-white);
}

.mobile {
  padding: 10px;
}

.tablet {
  display: flex;
  justify-content: center;
  height: 60px;
  padding: 10px;
}

@media screen and (max-height: 680px) {
  .sidebar-item {
    padding: 10px 30px;
    font-size: 16px;
  }
}
</style>
