<template>
  <div class="input-select-wrapper">
    <div ref="select" class="input-select" :class="{ open: open }">
      <label :for="id">{{ label ?? 'Compétences' }} :</label>
      <div class="input" @click="open = !open">
        <input
          :id="id"
          type="text"
          :placeholder="selected_values?.length == 0 ? 'Sélectionnez les compétence...' : ''"
        />
        <div class="selected-values">
          <CommonChip
            v-for="value of selected_values"
            :key="value.shortname"
            @click.stop="remove(value)"
            style="cursor: pointer"
            filled
            color="dark"
            >{{ value.shortname }}<X :size="10" style="margin-left: 5px" />
          </CommonChip>
        </div>
      </div>
      <ChevronUp class="input-select-chevron" @click="open = !open" />
      <div class="input-select-options">
        <div class="input-skills-search">
          <input placeholder="Rechercher.." @input="search" />
        </div>
        <div class="options">
          <div v-for="(option, key) in options" :key="key" class="input-select-option">
            <div v-if="!added(option)" @click="add(option)">
              <p>
                {{ option.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppData } from '@/stores/gae/store_appdata'
import { onClickOutside } from '@vueuse/core'
import { ChevronUp } from 'lucide-vue-next'
import type { Skill } from 'types/api/appdata'
import { computed, onMounted, ref } from 'vue'
import CommonChip from '../CommonChip.vue'
import { X } from 'lucide-vue-next'

const emits = defineEmits(['update:modelValue', 'onChange'])
const appdata = useAppData()

const props = defineProps<{
  id: string
  modelValue?: Skill[]
  label?: string
  multiple?: boolean
  placeholder?: string
}>()

const open = ref(false)
const options = ref<Skill[]>([])
const select = ref()

function add(skill: Skill) {
  selected_values.value?.push(skill)
  if (!props.multiple) {
    open.value = false
  }

  emits('onChange')
}

function remove(skill: Skill) {
  if (selected_values.value) {
    const i = selected_values.value.findIndex((e) => e.shortname == skill.shortname)
    selected_values.value.splice(i, 1)
  }
  emits('onChange')
}

function added(skill: Skill) {
  if (selected_values.value?.find((e) => e.shortname == skill.shortname)) {
    return true
  }
  return false
}

function search(event: Event) {
  const value = (event.target as HTMLInputElement).value
  if (value.length > 2) {
    options.value = appdata.getSkills.filter((e) =>
      e.name.toLowerCase().includes(value.toLowerCase())
    )
  } else {
    options.value = appdata.getSkills
  }
}

const selected_values = computed({
  get: () => {
    if (!props.modelValue) return undefined
    return props.modelValue
  },
  set: (v?: Skill[]): void => {
    emits('update:modelValue', v)
  }
})

onMounted(async () => {
  options.value = appdata.getSkills
})

onClickOutside(select, () => (open.value = false))
</script>

<style scoped lang="scss">
.selected-values {
  top: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: calc(100% - 60px);
  padding: 20px;
}

.input-select {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-select-options {
  top: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--vc-white);
  border: 0px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 0px 0px 5px 5px;
  z-index: 100;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 0px;
  overflow: scroll;
}

.input-select-chevron {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  top: 50px;
  right: 20px;
  cursor: pointer;
}

.input-select > label {
  margin-bottom: 15px;
  pointer-events: none;
}

.input-select > div {
  cursor: pointer;
}

.input-select > div > input {
  pointer-events: none;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  background-color: transparent;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px;
  position: absolute;
  height: 100%;
}

.input-select-options::-webkit-scrollbar {
  display: none;
}

.input-select.open .input-select-options {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 300px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-top: unset;
}

.input-select.open .input-select-options .input-select-option > div {
  border-top: 1px solid rgba(var(--vcrgb-dark), 0.2);
}

.input-select-option > div {
  padding: 20px 0px;
}

.input-select-option {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  padding: 0px 20px;
}

.input-select-option:hover {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: rgba(var(--vcrgb-dark), 0.05);
}

.error > div > input {
  margin-bottom: 5px;
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}

.input-select.open > div > input {
  border-bottom: unset;
  border-radius: 5px 5px 0px 0px;
}

.input-select.open > .input-select-chevron {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotate(-180deg);
}

.input {
  position: relative;
  min-height: 60px;
}

.input-skills-search {
  padding: 0 20px;
}

.input-skills-search > input {
  border: 0;
  top: 0;
  border-top: 1px solid rgba(var(--vcrgb-dark), 0.2);
  width: 100%;
  padding: 10px 0;
}
</style>
