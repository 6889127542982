<template>
  <CommonHeader title="Matériel manquant" />
  <LayoutContainer :max-width="1400">
    <CommonParagraph
      title="Déclarer des matériels manquants et le nombre de gobelets"
      class="mb-30"
    >
      <CompositeTodo
        readonly
        @on-clear="clear"
        :items="
          store.missing_materials.map((e) => ({
            label: e.content ?? '',
            value: e.id?.toString() ?? ''
          }))
        "
        v-model="todo"
      />
    </CommonParagraph>

    <InputCommon
      id="consultant-search"
      style="margin-bottom: 15px"
      label="Ajouter un matériel manquant ou le nombre de gobelets en votre possession"
      placeholder="Description du matériel manquant / nombre de gobelets"
      v-model="input"
    />
    <CommonButton icon="Plus" @click="add">
      Ajouter un matériel manquant supplémentaire
    </CommonButton>
  </LayoutContainer>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import CommonParagraph from '@/components/common/CommonParagraph.vue'
import InputCommon from '@/components/common/input/InputCommon.vue'
import CompositeTodo from '@/components/composite/CompositeTodo.vue'
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import { useMaterialStore } from '@/stores/gae/store_material'
import { useSecurityStore } from '@/stores/gae/store_security'
import { onMounted, ref } from 'vue'

const todo = ref([])
const input = ref('')
const store = useMaterialStore()
const security = useSecurityStore()

function add() {
  if (security.is_authenticated && security.user) {
    store.addMissingMaterial(security.user?.id, input.value)
  }
}

function clear(value: string) {
  if (confirm('Souhaitez-vous supprimer ce matériel manquant ?')) {
    store.removeMissingMaterial(parseInt(value))
    input.value = ''
  }
}

onMounted(() => {
  if (security.is_authenticated && security.user) {
    store.fetchMissingMaterials(security.user.id)
  }
})
</script>

<style scoped>
.cancel {
  cursor: pointer;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: fit-content;
  color: var(--vc-dark);
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
}
.cancel:hover {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  gap: 16px;
}
</style>
