<template>
  <div class="composite-consultant-stats">
    <CommonParagraph title="Statistiques" />
    <InputDate
      label="Date"
      year-picker
      id="input-date"
      v-model="statsStore.consultantDate"
      :yearRange="yearRange"
    />
    <div id="stat-grid">
      <CommonInsert id="stat-ca" title="CA Global sur l'année" column center>
        <InputSelect
          :options="skillOptions"
          v-model="selectedSkill"
          id="test"
          label="Filtrer par compétences"
        />
        <CommonStatChip size="large" filled transparent color="success" extend>
          {{ getCosts('turnover') }} €
        </CommonStatChip>
        <CommonStatChip title="Honoraires" size="small" filled transparent color="dark">
          {{ getCosts('costs') }}€
        </CommonStatChip>
        <CommonStatChip title="Déplacement" size="small" filled transparent color="dark">
          {{ getCosts('travel') }}€
        </CommonStatChip>
        <CommonStatChip title="Frais supp." size="small" filled transparent color="dark">
          {{ getCosts('fees') }}€
        </CommonStatChip>
        <CommonStatChip title="Charges moy." size="small" filled transparent color="dark">
          {{ getCosts('charge') }}€
        </CommonStatChip>
      </CommonInsert>
      <CommonInsert id="stat-canceled" title="Refus et Annulations">
        <CommonStatChip size="large" filled transparent color="danger" legend="Nbr de Refus">
          {{ statsStore.consultantActivity?.countInterventionsRefused }}
        </CommonStatChip>
        <CommonStatChip
          size="large"
          filled
          transparent
          color="success"
          legend="Nbr d'annulations GAE"
        >
          {{ statsStore.consultantActivity?.countInterventionsCanceled }}
        </CommonStatChip>
      </CommonInsert>
      <CommonInsert id="stat-delay" title="Délai de rendu des documents">
        <CommonStatChip
          size="large"
          filled
          transparent
          color="success"
          legend="Délai moyen de rendu de documents d'après mission"
        >
          {{ statsStore.consultantActivity?.averageDocumentSendingDelay }} J
        </CommonStatChip>
      </CommonInsert>
      <CommonInsert id="day-stats" title="Nombre d'interventions" column center>
        <CommonStatChip size="large" filled transparent color="success" legend="Missions réalisés">
          {{ statsStore.consultantActivity?.countInterventions }}
        </CommonStatChip>
        <div class="days-stats">
          <CommonStatChip size="large" filled transparent color="success" legend="Journées">
            {{ statsStore.consultantActivity?.countInterventionsFullDay }}
          </CommonStatChip>
          <CommonStatChip size="large" filled transparent color="success" legend="Demi-journées">
            {{ statsStore.consultantActivity?.countInterventionsHalfDay }}
          </CommonStatChip>
        </div>
      </CommonInsert>
      <CommonInsert
        id="graph-stats"
        title="Répartition de l'activité par catégories de formation"
        column
      >
        <template v-if="statsStore.getConsultantActivity?.countInterventions > 0">
          <div class="activity-select">
            <InputSelect v-model="selectedData" :options="dataOptions" id="data-select" />
            <InputSelect v-model="selectedUnit" :options="unitOptions" id="unit-select" />
          </div>
          <CompositeChart
            :label="getChartLabel()"
            :chartData="getChartData(selectedData)"
            :dataLabels="selectedUnit"
            :legend="true"
            :separator="true"
          />
        </template>
        <template v-else>
          <LabelInfo> Aucune donnée pour cette période. </LabelInfo>
        </template>
      </CommonInsert>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonParagraph from '../common/CommonParagraph.vue'
import CommonInsert from '../common/CommonInsert.vue'
import CommonStatChip from '../common/CommonStatChip.vue'
import InputSelect from '../common/input/InputSelect.vue'
import CompositeChart from './CompositeChart.vue'
import { useStatsStore } from '@/stores/gae/store_stats'
import { onMounted, ref, watch } from 'vue'
import type { Consultant } from 'types/api/consultant'
import InputDate from '../common/input/InputDate.vue'
import LabelInfo from './label/LabelInfo.vue'

const props = defineProps<{
  consultant: Consultant
}>()

const selectedData = ref<'day' | 'halfday' | 'total' | undefined>('total')
const selectedUnit = ref<'percentage' | 'value' | 'hours' | undefined>('percentage')
const selectedSkill = ref<'all' | 'formation'>('all')
const yearRange = [2020, new Date().getFullYear()]

const dataOptions = [
  { value: 'day', label: 'Journée' },
  { value: 'halfday', label: 'Demi-Journées' },
  { value: 'total', label: 'Total' }
]

const unitOptions = [
  { value: 'percentage', label: 'Pourcentage' },
  { value: 'value', label: 'Nombre' },
  { value: 'hours', label: 'Heures' }
]

const skillOptions = [
  { value: 'all', label: 'Toutes' },
  { value: 'formation', label: 'Formation' }
]

const statsStore = useStatsStore()

function getChartLabel() {
  if (selectedData.value === 'day') return 'Journées'
  else if (selectedData.value === 'halfday') return 'Demi-journées'
  else {
    return 'Total'
  }
}
function getLabels() {
  const labels = statsStore.getConsultantActivity?.distribution.map((d) => d.categoryName)
  return labels
}

function getColors() {
  return ['#7cb7ef', '#c19bee', '#738ce8', '#f9e471', '#9fd6b9']
}

function getChartData(type: 'day' | 'halfday' | 'total' | undefined) {
  const chartData = {
    labels: getLabels(),
    datasets: [
      {
        backgroundColor: getColors(),
        data: getData()
      }
    ]
  }
  return chartData
}

function getData() {
  if (selectedData.value === 'day') return getFullDayData()
  else if (selectedData.value === 'halfday') return getHalfDayData()
  else {
    return getTotalData()
  }
}

function getFullDayData() {
  if (selectedUnit.value === 'hours') {
    return statsStore.getConsultantActivity.distribution.map((d) => d.countFullDaysHours)
  } else {
    return statsStore.getConsultantActivity.distribution.map((d) => d.countFullDays)
  }
}

function getHalfDayData() {
  if (selectedUnit.value === 'hours') {
    return statsStore.getConsultantActivity.distribution.map((d) => d.countHalfDaysHours)
  } else {
    return statsStore.getConsultantActivity.distribution.map((d) => d.countHalfDays)
  }
}

function getTotalData() {
  if (selectedUnit.value === 'hours') {
    return statsStore.getConsultantActivity.distribution.map((d) => d.countHours)
  } else {
    return statsStore.getConsultantActivity.distribution.map((d) => d.countInterventions)
  }
}

function getCosts(type: 'turnover' | 'fees' | 'travel' | 'charge' | 'costs') {
  const billingMap = {
    formation: {
      turnover: statsStore.getConsultantBilling.formationTurnover,
      costs: statsStore.getConsultantBilling.formationAverageInterventionCost,
      fees: statsStore.getConsultantBilling.formationAdditionalFees,
      travel: statsStore.getConsultantBilling.formationTravelExpenses,
      charge: statsStore.getConsultantBilling.formationAverageChargeAmount
    },
    all: {
      turnover: statsStore.getConsultantBilling.turnover,
      costs: statsStore.getConsultantBilling.averageInterventionCost,
      fees: statsStore.getConsultantBilling.additionalFees,
      travel: statsStore.getConsultantBilling.travelExpenses,
      charge: statsStore.getConsultantBilling.averageChargeAmount
    }
  }

  return billingMap[selectedSkill.value][type]
}

watch(
  () => statsStore.consultantDate,
  () => {
    statsStore.fetchConsultantStats(props.consultant.id)
  }
)

onMounted(() => {
  statsStore.fetchConsultantStats(props.consultant.id)
})
</script>

<style scoped>
#stat-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1.3fr 2fr;
  gap: 22px;
}

#stat-ca {
  grid-area: 1 / 1 / span 2 / span 1;
}

#stat-ca > div > p {
  line-height: 110%;
  font-family: 'Roboto';
  opacity: 0.75;
  color: var(--vc-dark);
}

#stat-canceled {
  grid-area: 1 / 2 / span 1 / span 1;
}

#stat-delay {
  grid-area: 2 / 2 / span 1 / span 1;
}

#day-stats {
  grid-area: 3 / 1 / span 1 / span 1;
}

#graph-stats {
  grid-area: 3 / 2 / span 1 / span 1;
}

.composite-consultant-stats {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-bottom: 20px;
}

.costs {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.costs > p {
  font-weight: 500;
  font-size: 16px;
}

.activity-select {
  display: flex;
  gap: 10px;
}

.days-stats {
  display: flex;
  gap: 10px;
}

#input-select {
  max-width: 300px;
}
</style>
