<template>
	<component :is="icon" :size="size" :color="color" :stroke-width="strokeWidth" :default-class="defaultClass" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import * as icons from "lucide-vue-next";

export type Icons = keyof typeof icons;
const props = defineProps<{
	name: Icons,
	size?: number,
	color?: string,
	strokeWidth?: number,
	defaultClass?: string,
}>();

const icon = computed(() => icons[props.name]);
</script>
