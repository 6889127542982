<template>
  <div class="composite-zones">
    <div class="composite-zones-header">
      <InputCommon
        id="search"
        v-model="search"
        label="Rerchercher un département"
        placeholder="Code/Nom département, Code/Nom région..."
      />
      <div class="composite-zones-number">
        <span :class="{ selected: order == 1 }" @click="set_order(1)">1</span>
        <span :class="{ selected: order == 2 }" @click="set_order(2)">2</span>
        <span :class="{ selected: order == 3 }" @click="set_order(3)">3</span>
        <span :class="{ selected: order == 4 }" @click="set_order(4)">4</span>
        <span :class="{ selected: order == 5 }" @click="set_order(5)">5</span>
      </div>
    </div>
    <div class="composite-zone-regions">
      <p v-if="get_regions().length == 0">Aucun département trouvé.</p>
      <div v-for="region in get_regions()" class="composite-zone" :key="region.code">
        <h5 class="composite-zone-name">{{ region.code }} - {{ region.name }}</h5>
        <div class="composite-zone-zones">
          <X
            class="composite-zone-clear"
            v-if="!readonly && inputs[region.code]"
            @click="() => (inputs[region.code] = undefined)"
            :size="16"
            color="rgba(var(--vcrgb-dark), 0.5)"
          />
          <div class="custom-radio">
            <input
              v-model="inputs[region.code]"
              :value="1"
              :disabled="readonly"
              :name="`zone-${region.code}`"
              type="radio"
            />
            <span></span>
          </div>
          <div class="custom-radio">
            <input
              v-model="inputs[region.code]"
              :value="2"
              :disabled="readonly"
              :name="`zone-${region.code}`"
              type="radio"
            />
            <span></span>
          </div>
          <div class="custom-radio">
            <input
              v-model="inputs[region.code]"
              :value="3"
              :disabled="readonly"
              :name="`zone-${region.code}`"
              type="radio"
            />
            <span></span>
          </div>
          <div class="custom-radio">
            <input
              v-model="inputs[region.code]"
              :value="4"
              :disabled="readonly"
              :name="`zone-${region.code}`"
              type="radio"
            />
            <span></span>
          </div>
          <div class="custom-radio">
            <input
              v-model="inputs[region.code]"
              :value="5"
              :disabled="readonly"
              :name="`zone-${region.code}`"
              type="radio"
            />
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { X } from 'lucide-vue-next'
import InputCommon from '../common/input/InputCommon.vue'
import { FR_REGIONS, type Region } from '@/services/service_geo'
import { computed, ref, watch } from 'vue'
import { i0 } from '@/services/service_date'

interface IDictionary {
  [index: string]: number | undefined
}

export type FormInputLayout = {
  1: string
  2: string
  3: string
  4: string
  5: string
}

const props = defineProps<{
  modelValue?: FormInputLayout
  readonly?: boolean
}>()

const search = ref('')
const order = ref<number>(0)

/**
 * Converts a FormInputLayout to an IDictionary
 */
function initialize_input(input?: FormInputLayout): IDictionary {
  const output: IDictionary = {}
  for (let i = 0; i < FR_REGIONS.length; i++) {
    output[FR_REGIONS[i].code] = undefined
  }

  const parse = (i: 1 | 2 | 3 | 4 | 5) => {
    return input?.[i].split(',').map((e) => {
      output[e] = i
    })
  }

  parse(1)
  parse(2)
  parse(3)
  parse(4)
  parse(5)

  return output
}

function set_order(n: number) {
  if (order.value === n) order.value = 0
  else order.value = n
}

function order_sort(region: Region): 0 | 1 {
  if (inputs.value[region.code] === order.value) return 0
  return 1
}

function get_regions(): Region[] {
  const output: Region[] = []
  if (search.value.length < 2) {
    if (order.value !== 0) {
      return [...FR_REGIONS].sort((a, b) => order_sort(a) - order_sort(b))
    } else {
      return [...FR_REGIONS]
    }
  }

  for (const region of FR_REGIONS) {
    if (
      region.code.includes(search.value) ||
      region.name.toLowerCase().includes(search.value.toLowerCase()) ||
      region.region.toString().includes(search.value) ||
      region.region_name.toLowerCase().includes(search.value.toLowerCase())
    ) {
      output.push(region)
    }
  }

  return output
}

/**
 * Converts a Dictionary (department: zone) to a FormInputLayout (1: '01,02', 2: '03', ...) value
 */
function output(value: IDictionary): FormInputLayout {
  const values: {
    1: string[]
    2: string[]
    3: string[]
    4: string[]
    5: string[]
  } = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: []
  }

  for (const department in Object.keys(value)) {
    const zone = value[i0(department)] as 1 | 2 | 3 | 4 | 5
    if (zone) {
      values[zone].push(i0(department).toString())
    }
  }

  return {
    '1': values[1].join(','),
    '2': values[2].join(','),
    '3': values[3].join(','),
    '4': values[4].join(','),
    '5': values[5].join(',')
  }
}

const inputs = ref<IDictionary>(initialize_input(props.modelValue))
watch(inputs, (v) => emits('update:modelValue', output(v)), { deep: true })

function update(v: FormInputLayout) {
  inputs.value = initialize_input(v)
}

const emits = defineEmits<{
  (e: 'update:modelValue', value: FormInputLayout): void
}>()

defineExpose({ update })
</script>

<style scoped>
.composite-zone-regions {
  max-height: 500px;
  overflow-y: scroll;
  overflow-y: overlay;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
}
.composite-zones-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
  gap: 60px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    gap: 30px;
  }
}

.composite-zones-header .input-common {
  @media (max-width: 768px) {
    width: 100%;
  }
}

.composite-zones-header > div:first-child {
  flex: 1;
}

.composite-zones-number {
  display: flex;
  gap: 20px;
  padding-right: 32px;
}

.composite-zones-number > span {
  transition: 300ms cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--vc-dark);
  color: var(--vc-white);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

.composite-zones-number > span:hover {
  transition: 300ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: rgba(var(--vcrgb-dark), 0.9);
}

.composite-zone {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px 10px 10px;
}

.composite-zone:nth-child(2n) {
  background-color: rgba(var(--vcrgb-dark), 0.05);
}

.composite-zone:last-child {
  margin-bottom: 0;
}

.composite-zone-zones {
  display: flex;
  gap: 20px;
}

.composite-zone-zones > input,
.custom-radio input {
  width: 25px;
  margin: 0;
  cursor: pointer;
  accent-color: var(--vc-dark);
  height: 100%;
}

.custom-radio {
  width: 25px;
  max-height: 18px;
  display: flex;
  justify-content: center;
}

.custom-radio input:disabled:checked {
  display: none;
}

.custom-radio input:disabled:checked + span {
  position: relative;
  display: inline-block;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 80px;
  border: 2px solid #6c6c6c;
  box-sizing: border-box;
}

.custom-radio input:disabled:checked + span::after {
  content: '';
  display: inline-block;
  width: 10px;
  aspect-ratio: 1;
  background-color: #6c6c6c;
  border-radius: 80px;
  position: absolute;
  left: calc(50% - 5px);
  top: calc(50% - 5px);
}

.composite-zone-zones .custom-radio input:disabled {
  cursor: default;
}

.composite-zone-clear {
  cursor: pointer;
}

.selected {
  background-color: var(--vc-primary) !important;
}
</style>
