<template>
  <LayoutModal ref="modal">
    <CommonHeader class="mb-30" :title="title">
      <template #action>
        <CommonReturn @click="modal?.close()" />
      </template>
    </CommonHeader>
    <LayoutModalContent>
      <FormIntervention ref="form" />
      <CommonButton
        @click="submit"
        class="submit"
        icon="Save"
        filled
        :loading="intervention_store.isFeching"
      >
        Enregistrer l'intervention
      </CommonButton>
    </LayoutModalContent>
  </LayoutModal>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import LayoutModal from '@/components/layout/LayoutModal.vue'
import LayoutModalContent from '@/components/layout/LayoutModalContent.vue'
import { ref } from 'vue'
import FormIntervention from '../forms/FormIntervention.vue'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import type { Intervention } from 'types/api/intervention'
import CommonReturn from '@/components/common/CommonReturn.vue'
import { getContract } from '@/services/service_intervention'
import { useToast } from 'vue-toast-notification'

defineExpose({ edit })
const intervention_store = useInterventionStore()
const modal = ref<InstanceType<typeof LayoutModal>>()
const form = ref<InstanceType<typeof FormIntervention>>()
const title = ref('')
const id = ref<number>()

function edit(intervention: Intervention) {
  const copy = Object.assign({}, intervention)
  id.value = copy.id
  if (modal.value) {
    title.value = copy.complement ?? ''
    modal.value.open().then(() => {
      form.value?.load(copy)
    })
  }
}

async function submit() {
  if (!form.value) return
  const validation = await form.value.getValidation()
  if (!validation) {
    const toast = useToast()
    toast.error('Certains champs sont invalides.')
    return
  }

  const contract = getContract(form.value.getData())
  if (contract && id.value) {
    intervention_store.patch(id.value, contract).then(() => {
      modal.value?.close()
    })
  }
}
</script>

<style scoped>
.submit {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 150px;
}
</style>
