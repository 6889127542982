import router from '@/router'
import { useSecurityStore } from '@/stores/gae/store_security'
import axios, { AxiosError } from 'axios'
import type { APIException } from 'types/api/exceptions'
import { useToast } from 'vue-toast-notification'

export function useGAEAPI() {
  const security = useSecurityStore()
  const instance = axios.create({
    withCredentials: true
  })

  instance.interceptors.response.use(null, (error: AxiosError<APIException>) => {
    if (error.response?.status == 401) {
      if (error.config?.url !== '/api/authentication_token') {
        const toast = useToast()
        if (security.is_authenticated) {
          toast.error('Vous avez été déconnecté.')
          security.is_authenticated = false
        }
        router.push('/')
      }
    }

    return Promise.reject(error)
  })

  return { instance }
}
