<template>
  <ModalCreateIntervention ref="modal_create" />
  <CommonHeader
    title="Calendrier"
    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis risus sit amet urna sodales ultricies. Praesent mattis quis mi sit amet malesuada. Aenean vitae dictum dolor, vel interdum enim. Proin sagittis ipsum metus."
  />
  <LayoutContainer class="view-calendar">
    <div class="view-calendar-left">
      <CompositeSmallCalendar v-model:current="current" />
      <CompositeInterventionListFilters />
    </div>
    <div class="view-calendar-right">
      <CommonButton icon="Plus" filled @click="modal_create?.create()" v-if="security.isAdmin">
        Créer une intervention
      </CommonButton>
      <CompositeInterventionList :current_day="current" />
    </div>
  </LayoutContainer>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import CompositeInterventionList from '@/components/composite/CompositeInterventionList.vue'
import CompositeSmallCalendar from '@/components/composite/CompositeSmallCalendar.vue'
import CompositeInterventionListFilters from '@/components/composite/CompositeInterventionListFilters.vue'
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import ModalCreateIntervention from '@/components/composite/modal/ModalCreateIntervention.vue'
import { useInterventionStore } from '@/stores/gae/store_intervention'
import { useUserStore } from '@/stores/gae/store_user'
import { useSecurityStore } from '@/stores/gae/store_security'

const current = ref(new Date())
const modal_create = ref<InstanceType<typeof ModalCreateIntervention>>()
const intervention_store = useInterventionStore()
const refresh_interval = ref<number>(0)
const user_store = useUserStore()
const security = useSecurityStore()

const startRoutine = () => {
  refresh_interval.value = setInterval(() => {
    if (!user_store.busy) {
      intervention_store.refresh()
    }
  }, 300000)
}

const stopRoutine = () => {
  clearInterval(refresh_interval.value)
}

onMounted(startRoutine)
onBeforeUnmount(stopRoutine)
</script>

<style scoped>
.view-calendar {
  width: 100%;
  gap: 30px;
}

.view-calendar-right {
  display: flex;
  flex-direction: column;
  max-width: 900px;
}
@media screen and (min-width: 640px) {
  .view-calendar-right {
    align-items: start;
  }
}

@media screen and (min-width: 1280px) {
  .view-calendar {
    display: grid;
    grid-template-columns: fit-content(300px) 1fr;
  }

  .view-calendar-right {
    align-items: end;
  }
}
</style>
