<template>
  <LayoutCard class="composite-file-card">
    <h6>{{ title }}</h6>
    <div class="composite-file-card-files">
      <template v-if="title.slice(0, 20) !== 'Rapport de formation'">
      <InputFile
        :readonly="readonly"
        ref="file_input"
        v-model="files"
        id="input-file"
        class="mb-10"
        accept="*"
        @on:upload="onFileUpload"
        @on:remove="onFileDelete"
      />
      </template>
      <div v-if="link">
        <div class="links">
          <CommonChip
            v-for="link in modelValue?.filter((e) => e.link)"
            class="links-link"
            size="medium"
            filled
            color="success"
            :key="link.id"
          >
            <a target="_blank" :href="link.link!">
              {{ link.link }}
            </a>
            <CommonIcon
              v-if="!readonly"
              class="input-link-delete"
              name="X"
              color="white"
              :size="16"
              @click="removeLink(link.id)"
            />
          </CommonChip>
        </div>
        <template
          v-if="title === 'Rapport de formation' || title === 'Evaluation de la sensibilisation'"
        >
          <div
            v-for="skill in intervention.skills.filter(
              (e) => e.category === getCategory(title) && e.jotformLink
            )"
            :key="skill.id"
            class="jotform-skills"
            @click="addClickedLink(skill.jotformLink)"
          >
            <CommonChip
              size="small"
              filled
              color="success"
              class="clicked-link"
              v-if="hasBeenClicked(skill.jotformLink)"
            >
              <CommonIcon name="Check" color="white" :size="13" :strokeWidth="3" />
              Visité
            </CommonChip>
            <a :href="skill.jotformLink" target="_blank"> Formulaire pour {{ skill.name }} </a>
          </div>
        </template>
        <template v-else-if="!readonly">
          <label for="input-file">Lien Jotform : </label>
          <input id="input-file" class="link" v-model="linkv" placeholder="https://gae.fr" />
          <button @click="onLink">Envoi</button>
        </template>
      </div>
    </div>
  </LayoutCard>
</template>

<script setup lang="ts">
import type { MediaObjectDTO } from 'types/api/file'
import LayoutCard from '../layout/LayoutCard.vue'
import InputFile from '../common/input/InputFile.vue'
import { onMounted, ref } from 'vue'
import CommonChip from '../common/CommonChip.vue'
import { useFile } from '@/stores/gae/store_file'
import type { Intervention, InterventionDoneDocument } from 'types/api/intervention'
import { useToast } from 'vue-toast-notification'
import CommonIcon from '../common/CommonIcon.vue'
import { useSecurityStore } from '@/stores/gae/store_security'

const props = defineProps<{
  title: string
  category: number
  intervention: Intervention
  link?: boolean
  modelValue?: InterventionDoneDocument[]
  readonly?: boolean
}>()

const file_input = ref<InstanceType<typeof InputFile>>()
const file_store = useFile()
const security_store = useSecurityStore()

const emits = defineEmits(['update:modelValue', 'update:modelValueLink'])

const files = ref<MediaObjectDTO[]>([])
const linkv = ref('')

function onFileUpload(file: MediaObjectDTO) {
  file_store
    .uploadInterventionDocument(props.intervention.id, file, props.category)
    .then((response) => {
      const toast = useToast()
      const documents = props.modelValue
      documents?.push({ id: response.data.id, mediaObject: file })
      emits('update:modelValue', documents)
      toast.success('Votre fichier a été enregistré')
    })
}
function getCategory(title: string) {
  if (title === 'Rapport de formation') return 'Formation'
  if (title === 'Evaluation de la sensibilisation') return 'Sensibilisation'
}

function onFileDelete(id: number) {
  const documents = props.modelValue
  if (documents) {
    const index = documents.findIndex((e) => e.mediaObject?.id === id)
    documents.splice(index, 1)
    emits('update:modelValue', documents)
  }
}

function removeLink(id: number) {
  file_store.deleteInterventionDocument(id).then(() => {
    const media_objects = props.modelValue
    if (media_objects) {
      const index = media_objects.findIndex((e) => e.id === id)
      media_objects.splice(index, 1)
      emits('update:modelValue', media_objects)
    }
  })
}

function addClickedLink(link: string) {
  if (hasBeenClicked(link) || !props.intervention.consultant) return
  if (security_store.user?.id !== props.intervention.consultant.id) return

  const clickedLink = {
    consultant_id: props.intervention.consultant.id,
    intervention_id: props.intervention.id,
    link: link
  }
  file_store.addClickedLink(clickedLink)
}

function hasBeenClicked(link: string) {
  const consultant = props.intervention.consultant
  if (!consultant) return false
  const index = file_store.clickedLinks.findIndex(
    (e) =>
      e.link === link &&
      e.consultant_id === consultant.id &&
      e.intervention_id === props.intervention.id
  )
  return index !== -1
}

function onLink() {
  if (!linkv.value) return

  file_store
    .uploadInterventionLink(props.intervention.id, linkv.value, props.category)
    .then((response) => {
      const toast = useToast()
      const media_objects = props.modelValue ?? []
      media_objects.push({ id: response.data.id, link: linkv.value })
      emits('update:modelValue', media_objects)
      linkv.value = ''
      toast.success('Votre lien a été enregistré')
    })
}

onMounted(() => {
  if (props.modelValue) {
    for (const document of props.modelValue) {
      if (document.mediaObject) {
        file_input.value?.addFile(document.mediaObject)
      }
    }
  }
  const categories = ['Rapport de formation', 'Evaluation de la sensibilisation']
  if (categories.includes(props.title) && props.intervention.consultant) {
    file_store.fetchClickedLinks(props.intervention.consultant.id)
  }
})
</script>

<style scoped>
.composite-file-card {
  display: flex;
  gap: 30px;
}

.composite-file-card > h6 {
  min-width: 235px;
  width: 235px;
}

.link {
  border-radius: 5px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  margin-top: 10px;
}

.links {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.links-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.jotform-skills {
  display: flex;
  align-items: center;
  gap: 10px;
}

.clicked-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.jotform-skills > a {
  color: var(--vc-dark);
  text-decoration: underline;
}

.input-link-delete {
  cursor: pointer;
}

.jotform-skills:not(:last-child) {
  margin-bottom: 15px;
}
</style>
