<template>
  <LayoutCard
    v-for="item in items"
    class="composite-pouch-card"
    :key="item.value"
    @click="select(item.value)"
  >
    <section class="pouch-wrapper">
      <div class="input-checkbox" :class="{ error: errors && errors.length > 0 }">
        <div>
          <Check class="icon" color="var(--vc-white)" :size="16" />
          <input type="checkbox" v-model="selected" :value="item.value" />
        </div>
      </div>
      <div class="info-wrapper">
        <CommonChip transparent color="dark">{{ item.skill }}</CommonChip>
        <h4>{{ item.packName }}</h4>
        <div class="infos">
          <p>{{ item.date }}</p>
          <p>{{ item.customer }}</p>
        </div>
      </div>
    </section>
    <div v-if="errors && errors.length > 0" class="input-checkbox__error">
      <AlertCircle :size="16" />
      <p>{{ errors[0].$message }}</p>
    </div>
  </LayoutCard>
</template>

<script setup lang="ts">
import CommonChip from '../common/CommonChip.vue'
import LayoutCard from '../layout/LayoutCard.vue'
import type { ErrorObject } from '@vuelidate/core'
import { Check } from 'lucide-vue-next'
import { AlertCircle } from 'lucide-vue-next'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    errors?: ErrorObject[]
    items: PouchOption[]
    modelValue?: (string | number)[]
  }>(),
  {}
)

const emits = defineEmits(['update:modelValue'])

export type PouchOption = {
  packName: string
  skill: string
  date: string
  value: string
  customer: string
}

const selected = computed({
  get: () => {
    return props.modelValue ?? []
  },
  set: (v?: (string | number)[]): void => {
    emits('update:modelValue', v)
  }
})

function select(value: string | number) {
  const index = selected.value.findIndex((v) => v === value)
  if (index == -1) {
    selected.value.push(value)
  } else {
    selected.value.splice(index, 1)
  }
  emits('update:modelValue', selected.value)
}
</script>

<style scoped lang="scss">
.composite-pouch-card {
  cursor: pointer;
}

.info-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
}

.infos {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.infos > p {
  color: rgba(var(--vcrgb-dark), 0.6);
}

.pouch-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.input-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;

  &__error {
    color: var(--vc-danger);
    font-size: 12px;
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 5px;
  }
}

.input-checkbox > div {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;

  background-color: var(--vc-white);
  width: 20px;
  height: 20px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 5px;
  cursor: pointer;
}

input[type='checkbox']:checked {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: var(--vc-primary);
}

.icon {
  position: absolute;
  z-index: 10;
  left: 0;
  width: 20px;
  pointer-events: none;
}

.error > input {
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}
</style>
