import { defineStore } from 'pinia'
import type { EventInput } from '@fullcalendar/core/index.js'
import { useInterventionStore } from './store_intervention'
import { get_holyday_array, holydays } from '@/services/service_offdays'

type CalendarStoreState = {
  highest_count: number
  current_day: Date
}

const current_date = new Date()
current_date.setHours(0, 0)

export const useCalendarStore = defineStore('calendar', {
  state: (): CalendarStoreState => ({
    highest_count: 0,
    current_day: current_date
  }),
  getters: {
    getCurrentDay: (state) => state.current_day,
    getHighestCount: (state) => state.highest_count,
    getHolydaysEvents: (state): EventInput[] => {
      const date = new Date();

      const prev = get_holyday_array(date.getFullYear() - 1);
      const curr = get_holyday_array(date.getFullYear());
      const next = get_holyday_array(date.getFullYear() + 1);
      const all = [...prev, ...curr, ...next];

      return all.map(e => {
        return {
          date: e.date,
          title: e.name,
          extendedProps: {
            isHolyday: true
          }
        }
      })
    },
    getSmallEvents: () => {
      const store = useInterventionStore()
      const output: EventInput[] = []
      store.interventions.forEach((intervention) => {
        if (intervention.event.extendedProps.count !== 0) {
          output.push(intervention.event)
        }
      })

      return output
    }
  },
  actions: {
    setCurrentDay(date: Date) {
      const store = useInterventionStore()
      const date_string = date.toISOString().split('T')[0];
      if (!store.fetchedMonths.includes(date_string)) {
        store.fetchMonth(date);
      }

      store.renderReset()
      this.current_day = date
    },
    updateHighestCount() {
      const store = useInterventionStore()
      store.interventions.forEach((day) => {
        if (day.interventions.length > this.highest_count) {
          this.highest_count = day.interventions.length
        }
      })
    }
  }
})
