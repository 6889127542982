<template>
  <CommonIconLabel icon="Sun" :size="size"> Disponibilités : {{ getValue() }} </CommonIconLabel>
</template>

<script setup lang="ts">
import CommonIconLabel from '@/components/common/CommonIconLabel.vue'

const props = withDefaults(
  defineProps<{
    day?: boolean
    night?: boolean
    weekend?: boolean
    size: 'small' | 'medium'
  }>(),
  {
    size: 'small'
  }
)

function getValue() {
  const availability: string[] = []
  if (props.day) {
    availability.push('Jour')
  }

  if (props.night) {
    availability.push('Nuit')
  }

  if (props.weekend) {
    availability.push('Week-end')
  }

  if (availability.length == 0) {
    return 'Aucune'
  }

  return availability.join(', ')
}
</script>
