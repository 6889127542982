import { defineStore } from 'pinia'
import { Axios, AxiosError, type AxiosResponse } from 'axios'
import { useGAEAPI } from '@/composable/gae_api'
import type {
  APIAuthenticationTokenRequest,
  APIAuthenticationTokenResponse
} from 'types/api/security'
import type { APIException } from 'types/api/exceptions'
import { useToast } from 'vue-toast-notification'
import type { User } from 'types/api/user'
import router from '@/router'
import { useStorage, type RemovableRef } from '@vueuse/core'
import { useAppData } from './store_appdata'

interface FetchMeCallback {
  (user?: User): void
}

type SecurityStoreState = {
  user?: User
  is_authenticated: RemovableRef<boolean>
  fetching: boolean
  switched: boolean
}

export const useSecurityStore = defineStore('security', {
  state: (): SecurityStoreState => ({
    user: undefined,
    is_authenticated: useStorage('is_authenticated', false),
    fetching: false,
    switched: false,
  }),
  getters: {
    isFetching: (state) => state.fetching,
    isAdmin: (state) => state.user?.roles?.includes('ROLE_ADMIN') && !state.switched,
    isAdminReadonly: (state) => state.user?.roles?.includes('ROLE_ADMIN_READONLY'),
    isConsultant: (state) => state.user?.roles?.includes('ROLE_CONSULTANT') && !state.switched
  },
  actions: {
    authenticate(email: string, password: string) {
      const api = useGAEAPI()
      const toast = useToast()
      this.fetching = true
      api.instance
        .post<
          APIAuthenticationTokenResponse,
          AxiosResponse<APIAuthenticationTokenResponse, any>,
          APIAuthenticationTokenRequest
        >('/api/authentication_token', { email, password }, { withCredentials: false })

        .then(async () => {
          this.fetchMe((user) => {
            const appdata = useAppData()
            appdata.fetch()
            this.is_authenticated = true
            toast.success(`Bienvenue, ${user?.firstname} ${user?.lastname}`)
            if (this.isAdmin || this.isAdminReadonly) {
              router.push('/extranet/calendar')
            } else {
              router.push('/extranet/')
            }
          })
        })
        .catch((e: AxiosError<APIException>) => {
          if (e.response) {
            toast.error(e.response.data.message)
          }
        })
        .finally(() => (this.fetching = false))
    },
    async switchRole() {
      if (this.user?.roles?.includes("ROLE_ADMIN")) {
        this.switched = !this.switched
      }
    },
    async fetchMe(cb?: FetchMeCallback) {
      const api = useGAEAPI()
      return api.instance
        .get<User>('/api/me')
        .then((response) => {
          this.user = response.data
          if (cb) cb(response.data)
        })
        .catch((e: AxiosError<APIException>) => {
          if (e.response) {
            const toast = useToast()
            toast.error(e.response.data.message)
          }
        })
    },
    async logout() {
      const api = useGAEAPI()
      api.instance
        .get('/api/logout')
        .catch((e: AxiosError<APIException>) => {
          if (e.response) {
            const toast = useToast()
            toast.error(e.response.data.message)
          }
        })
        .finally(() => {
          router.push('/')
          this.user = undefined
          this.is_authenticated = false
          const toast = useToast()
          toast.info('Vous avez été déconnecté.')
        })
    },
    async requestPassword(email: string) {
      const api = useGAEAPI()
      this.fetching = true;
      return api.instance
        .post('/api/reset-password', { email })
        .then(() => {
          router.push('/confirm-email')
        })
        .catch((e: AxiosError<APIException>) => {
          if (e.response) {
            const toast = useToast()
            toast.error(e.response.data.message)
          }
        }).finally(() => { this.fetching = false })
    },
    async resetPassword(password: string, token: string) {
      const api = useGAEAPI()
      this.fetching = true
      return api.instance
        .post(`/api/reset-password/${token}`, { password })
        .then(() => {
          const toast = useToast()
          toast.success('Votre mot de passe a été modifié avec succès.')
          router.push('/')
        })
        .catch((e: AxiosError<APIException>) => {
          if (e.response) {
            const toast = useToast()
            toast.error(e.response.data.message)
          }
        })
        .finally(() => (this.fetching = false))
    }
  }
})
