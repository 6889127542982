import type { TimeModel } from '@vuepic/vue-datepicker'

export function i0(value: string | number) {
  if (typeof value === 'string') {
    if (parseInt(value) < 10) return `0${value}`
  } else {
    if (value < 10) return `0${value}`
  }
  return value
}

export function getDateYMD(date?: Date) {
  if (!date) return ''
  return date.toISOString().split('T')[0];
}

export function getDateYMDsync(date?: Date) {
  if (!date) return ''
  const year = date.getFullYear();
  const month = i0(date.getMonth() + 1)
  const day = i0(date.getDate());


  return [year, month, day].join('-');
}

export function getDateYMsync(date?: Date) {
  if (!date) return ''
  const year = date.getFullYear();
  const month = i0(date.getMonth() + 1)
  return [year, month].join('-');
}

export function getDateFromTimeModel(model?: TimeModel) {
  if (!model) {
    return new Date().toISOString()
  }

  const date = new Date()
  const hour = parseInt(model.hours.toString())
  const minutes = parseInt(model.minutes.toString())
  date.setHours(hour, minutes)
  return `${i0(hour)}:${i0(minutes)}`
}

export function getTimeModelFromString(string?: string): TimeModel | undefined {
  if (!string) return undefined;

  const values = string.split(':')
  if (!values[0] || !values[1]) throw 'Unable to process string to time model'
  return {
    hours: parseInt(values[0]),
    minutes: parseInt(values[1])
  }
}

export function isTimeNight(start: TimeModel) {
  const hours = typeof start.hours === 'number' ? start.hours : parseInt(start.hours);
  if (hours >= 20 || hours < 6) return true
  return false
}

export function makeAddressString(address?: string, postal_code?: string, city?: string) {
  const outpout = [];
  if (address) outpout.push(address)
  if (postal_code) outpout.push(postal_code)
  if (city) outpout.push(city)

  return outpout.join(', ')
}

export function getMonthsOptions(): SelectOption[] {
  return [
    { label: 'Janvier', value: '1' },
    { label: 'Février', value: '2' },
    { label: 'Mars', value: '3' },
    { label: 'Avril', value: '4' },
    { label: 'Mai', value: '5' },
    { label: 'Juin', value: '6' },
    { label: 'Juillet', value: '7' },
    { label: 'Août', value: '8' },
    { label: 'Septembre', value: '9' },
    { label: 'Octobre', value: '10' },
    { label: 'Novembre', value: '11' },
    { label: 'Décembre', value: '12' }
  ];
}