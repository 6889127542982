<template>
  <ModalCreateConsultant ref="modal_create" />
  <CommonHeader title="Liste des consultants" />
  <LayoutContainer class="consultant-list" :max-width="1400">
    <div class="view-consultant-right">
      <CommonButton
        class="consultant-list-create"
        icon="Plus"
        filled
        @click="modal_create?.create()"
        v-if="security.isAdmin"
      >
        Ajouter un consultant
      </CommonButton>
    </div>
    <CompositeConsultantList />
  </LayoutContainer>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import CompositeConsultantList from '@/components/composite/CompositeConsultantList.vue'
import ModalCreateConsultant from '@/components/composite/modal/ModalCreateConsultant.vue'
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import { ref } from 'vue'
import { useSecurityStore } from '@/stores/gae/store_security'

const security = useSecurityStore()
const modal_create = ref<InstanceType<typeof ModalCreateConsultant>>()
</script>

<style scoped>
.composite-consultant-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.consultant-list-create {
  margin-bottom: 23px;
}

.view-consultant-right {
  display: flex;
  justify-content: end;
}
</style>
