<template>
  <div class="input-days">
    <label>{{ label }} :</label>
    <div class="input-days-wrapper">
      <div
        v-for="(day, i) in days"
        class="input-days-day"
        :class="{ selected: isToggle(i) }"
        @click="toggle(i)"
      >
        {{ day }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  label: string
  modelValue: number[]
}>()

const emits = defineEmits(['update:modelValue'])
const days = 'LMMJVSD'

function toggle(n: number) {
  const values = props.modelValue
  const index = values.findIndex((e) => e === n)

  if (index !== -1) {
    values.splice(index, 1)
  } else {
    values.push(n)
  }

  emits('update:modelValue', values)
}

function isToggle(n: number) {
  return props.modelValue.findIndex((e) => e === n) !== -1
}
</script>

<style scoped>
.input-days > label {
  display: block;
  margin-bottom: 15px;
}

.input-days-wrapper {
  display: flex;
  gap: 10px;
}

.input-days-day {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  border: 1px solid var(--vc-dark);
  color: rgba(var(--vcrgb-dark), 0.8);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-days-day.selected {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: var(--vc-primary);
  border: 1px solid white;
  color: white;
}
</style>
