<template>
  <div class="input-select-wrapper" :class="{ error: errors && errors.length > 0, disabled }">
    <div ref="select" class="input-select" :class="{ open: open }">
      <label v-if="label" :for="id">{{ label }}{{ required ? '*' : '' }} :</label>
      <div @click="open = !open">
        <input :id="id" type="text" :placeholder="placeholder" :value="getValue()" />
      </div>
      <X v-if="clearable && selected_value" @click="selected_value = undefined" class="input-select-clear"
        :stroke-width="1.5" :size="20" />
      <ChevronUp class="input-select-chevron" @click="open = !open" :style="{ top: label ? '50px' : '18px' }" />
      <div class="input-select-options">
        <div v-for="(option, key) in options" :key="key" class="input-select-option"
          :class="{ readonly: option.readonly }" @click="selectOption(key)">
          <div>
            <slot v-if="$slots.option" :option="option" name="option" />
            <p v-else>
              {{ option.label }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="errors && errors.length > 0" class="input-select__error">
      <AlertCircle :size="16" />
      <p>{{ errors[0].$message }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
import { onClickOutside } from '@vueuse/core'
import { ChevronUp, AlertCircle, X } from 'lucide-vue-next'
import { computed, ref } from 'vue'

const emits = defineEmits(['update:modelValue'])

const props = withDefaults(
  defineProps<{
    id: string
    label?: string
    modelValue?: string
    placeholder?: string
    required?: boolean
    options: SelectOption[]
    errors?: ErrorObject[]
    disabled?: boolean
    clearable?: boolean
    valueBy?: ValueBy
    labelBy?: LabelBy
  }>(),
  {
    placeholder: 'Sélectionnez une option...'
  }
)

const open = ref(false)
const select = ref()

const selected_value = computed({
  get: () => {
    if (!props.modelValue) return undefined
    return props.modelValue
  },
  set: (v?: string): void => {
    emits('update:modelValue', v)
  }
})

function getValue() {
  if (selected_value.value) {
    const option = getOptionFromValue(selected_value.value)
    return option?.label
  }
  return ''
}

function getOptionFromValue(value: string) {
  for (let i = 0; i < props.options.length; i++) {
    if (props.options[i].value === value) return props.options[i]
  }
}

function selectOption(id: number) {
  const option = props.options[id]
  selected_value.value = option.value
  open.value = false
}

onClickOutside(select, () => (open.value = false))
</script>

<style scoped lang="scss">
.input-select {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__error {
    color: var(--vc-danger);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.input-select-options {
  position: absolute;
  top: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--vc-white);
  border: 0px solid rgba(var(--vcrgb-dark), 0.2);
  border-radius: 0px 0px 5px 5px;
  z-index: 10;
}

.input-select-chevron {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.input-select-clear {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  top: 50px;
  right: 50px;
}

.input-select>label {
  margin-bottom: 15px;
  pointer-events: none;
}

.input-select>div {
  cursor: pointer;
}

.input-select>div>input {
  pointer-events: none;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  background-color: transparent;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px;
}

.input-select-options {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 0px;
  overflow: scroll;
}

.input-select-options::-webkit-scrollbar {
  display: none;
}

.input-select.open .input-select-options {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 300px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  border-top: unset;
}

.input-select.open .input-select-options .input-select-option>div {
  border-top: 1px solid rgba(var(--vcrgb-dark), 0.2);
}

.input-select-option>div {
  padding: 20px 0px;
}

.input-select-option {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  padding: 0px 20px;
}

.input-select-option:hover {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: rgba(var(--vcrgb-dark), 0.05);
}

.error>div>input {
  margin-bottom: 5px;
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}

.input-select.open>div>input {
  border-bottom: unset;
  border-radius: 5px 5px 0px 0px;
}

.input-select.open>.input-select-chevron {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotate(-180deg);
}

.readonly {
  display: none;
}
</style>
