<template>
  <div class="input-quill" :class="{ error: errors && errors.length > 0 }">
    <label :for="id">{{ label }}{{ required ? '*' : '' }} : </label>
    <QuillEditor :placeholder="placeholder" v-model:content="input" contentType="html" />
  </div>
  <div v-if="errors && errors.length > 0" class="input-area__error">
    <AlertCircle :size="16" />
    <p>{{ errors[0].$message }}</p>
  </div>
</template>

<script setup lang="ts">
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { computed } from 'vue'
import type { ErrorObject } from '@vuelidate/core'
import { AlertCircle } from 'lucide-vue-next'

const props = defineProps<{
  placeholder?: string
  modelValue?: string
  id: string
  label: string
  required?: boolean
  errors?: ErrorObject[]
}>()

const emits = defineEmits(['update:modelValue'])

const input = computed({
  get: () => {
    if (props.modelValue) return props.modelValue
  },
  set: (v?: string): void => {
    emits('update:modelValue', v)
  }
})
</script>

<style scoped lang="scss">
.input-quill {
  display: flex;
  flex-direction: column;

  &__error {
    color: var(--vc-danger);
    font-size: 12px;
    display: flex;
    margin-top: 5px;
    align-items: center;
    gap: 5px;
  }
}

.error > textarea {
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}
</style>
