<template>
  <CommonHeader :title="route.params.id ? 'Facturations du consultant' : 'Ma facturation'" />
  <LayoutContainer class="view-billing">
    <InputDate
      month-picker
      label="Date"
      id="date"
      class="input-date"
      v-model="date"
      @update:model-value="update_date"
    />
    <table>
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Nom mission</th>
          <th scope="col">Nom client</th>
          <th scope="col">Journée / Demi journée</th>
          <th scope="col">Zone</th>
          <th scope="col">Forfait</th>
          <th scope="col">Frais supplémentaires</th>
          <th scope="col">Total HT</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="billing in billing_store.getBillings">
          <td>{{ billing.date ? new Date(billing.date).toLocaleDateString('fr-FR') : '-' }}</td>
          <td>{{ billing.mission }}</td>
          <td>{{ billing.client }}</td>
          <td>{{ billing.day }}</td>
          <td>{{ billing.zone }}</td>
          <td>{{ billing.package }}</td>
          <td>{{ billing.additional }}</td>
          <td>{{ billing.total }}</td>
        </tr>
        <tr>
          <td style="border: 0"></td>
          <td style="border: 0"></td>
          <td style="border: 0"></td>
          <td style="border: 0"></td>
          <td style="border: 0"></td>
          <td style="border: 0; text-align: right; font-weight: 600">TOTAL</td>
          <td style="font-weight: 600">{{ getTotalAdditionnal() }}</td>
          <td style="font-weight: 600">{{ getTotal() }}</td>
        </tr>
      </tbody>
    </table>
  </LayoutContainer>
</template>

<script setup lang="ts">
import CommonHeader from '@/components/common/CommonHeader.vue'
import InputDate from '@/components/common/input/InputDate.vue'
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import { useBillingStore } from '@/stores/gae/store_billing'
import { useSecurityStore } from '@/stores/gae/store_security'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const billing_store = useBillingStore()
const user_store = useSecurityStore()
const route = useRoute()
const yesterday = new Date()
yesterday.setDate(10)
yesterday.setMonth(yesterday.getMonth() - 1)
const date = ref(yesterday)

function getTotalAdditionnal() {
  let sum = 0
  for (let i = 0; i < billing_store.getBillings.length; i++) {
    const value = billing_store.getBillings[i].additional
    sum += value
  }
  return sum
}

function getTotal() {
  let sum = 0
  for (let i = 0; i < billing_store.getBillings.length; i++) {
    const value = billing_store.getBillings[i].total
    sum += value
  }
  return sum
}

function update_date() {
  const user_id = route.params.id as string
  if (user_id) {
    billing_store.fetchBillings(parseInt(user_id), date.value)
  } else if (user_store.user) {
    billing_store.fetchBillings(user_store.user.id, date.value)
  }
}

onMounted(() => {
  const user_id = route.params.id as string
  if (user_id) {
    billing_store.fetchBillings(parseInt(user_id), date.value)
  } else if (user_store.user) {
    billing_store.fetchBillings(user_store.user.id, date.value)
  }
})
</script>

<style scoped>
.view-billing {
  padding: 30px 50px;
  overflow-x: scroll;
  min-height: calc(100vh - 160px);
}

table {
  border-collapse: collapse;
  overflow: hidden;
  min-width: 1350px;
  max-width: 1600px;
  text-align: left;
  empty-cells: hide;
}

th {
  padding: 10px;
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  background-color: rgba(var(--vcrgb-dark), 0.1);
  color: var(--vc-dark);
}

td {
  padding: 10px;
  color: var(--vc-black);
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
}

tr th,
td {
  width: 1%;
  white-space: nowrap;
}

.input-date {
  max-width: 350px;
  margin-bottom: 30px;
}
</style>
