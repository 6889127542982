<template>
  <div class="modal-availability-wrapper">
    <CommonButton filled icon="SquareStack" @click="state = !state">
      Selectionner une plage
    </CommonButton>
    <div ref="modal" class="modal-availability" v-if="state">
      <label for="datepicker">Plage de date :</label>
      <DatePicker
        class="mb-20"
        id="datepicker"
        input-class-name="datepicker-input"
        v-model="form.range"
        hide-input-icon
        locale="fr"
        :enable-time-picker="false"
        range
        select-text="Valider"
        cancel-text="Annuler"
      />
      <InputDays
        v-if="form.available"
        class="mb-20"
        label="Jours de la semaine"
        v-model="form.days"
      />
      <InputBoolean
        v-model="form.available"
        class="mb-20"
        id="availability-available"
        label="Disponible"
      />
      <CommonButton :disabled="!form.range" filled @click="submit">Confirmer</CommonButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import InputBoolean from '@/components/common/input/InputBoolean.vue'
import DatePicker from '@vuepic/vue-datepicker'
import InputDays from '@/components/common/input/InputDays.vue'
import { useAvailabilityStore } from '@/stores/gae/store_availability'
import { onClickOutside } from '@vueuse/core'
import { reactive, ref } from 'vue'
import { useToast } from 'vue-toast-notification'

const props = defineProps<{
  user: number
}>()

const availaibility_store = useAvailabilityStore()
const default_state = {
  days: [0, 1, 2, 3, 4],
  available: true
}

const toast = useToast()
const state = ref(false)
const modal = ref()
const form = reactive<{
  range?: Date[]
  days: number[]
  available: boolean
}>(default_state)

onClickOutside(modal, () => {
  state.value = false
})

function submit() {
  if (!form.range) return
  if (form.available) {
    availaibility_store.create(props.user, form.range[0], form.range[1], form.days).then(() => {
      toast.success('Vos disponibilités ont été mises à jour.')
    })
  } else {
    availaibility_store.remove(props.user, form.range[0], form.range[1]).then(() => {
      toast.success('Vos disponibilités ont été mises à jour.')
    })
  }
  state.value = false
}
</script>

<style scoped>
.modal-availability-wrapper {
  position: relative;
}

.modal-availability {
  z-index: 10;
  padding: 20px;
  width: 470px;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.219);
  position: absolute;
  background-color: white;
  border-radius: 5px;
  top: 0;
  right: calc(100% + 10px);
}

.modal-availability > label {
  display: block;
  margin-bottom: 15px;
}
</style>
