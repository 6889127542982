import type { APIConsultantPATCHRequest, ConsultantForm } from "types/api/consultant"

export function contract(data: ConsultantForm): APIConsultantPATCHRequest | undefined {
	return {
		firstname: data.firstname,
		lastname: data.lastname,
		email: data.email,
		is_expert_patient: data.expert,
		availability: data.availability,
		skills: data.skills?.map((s) => s.id),
		acronym: data.acronym,
		address: data.address,
		postcode: data.postcode,
		city: data.city,
		phone: data.phone,
		job: data.job,
		onedrive: data.onedrive,
		departements_by_zones: makeZones(data.zones)
	}
}

function makeZones(zones: {
	1: string
	2: string
	3: string
	4: string
	5: string
}): APIConsultantPATCHRequest['departements_by_zones'] {
	function make(zone: string) {
		if (zone === '') return [];
		return zone.split(',').map((d) => d.trim())
	}

	return {
		"1": make(zones[1]),
		"2": make(zones[2]),
		"3": make(zones[3]),
		"4": make(zones[4]),
		"5": make(zones[5])
	}
}	