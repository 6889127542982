<template>
  <div class="composite-popup-menu" ref="menu">
    <CommonIconButton color="light" icon="MoreHorizontal" @click.stop="buttonClick" />
    <CommonIconButton
      v-if="is_open"
      @click="is_open = false"
      class="close-button"
      color="dark"
      icon="X"
    />
    <div
      class="popup-wrapper"
      :class="[is_open ? '' : 'closed', x > width / 2 ? '' : 'left', y > height / 2 ? 'bot' : '']"
    >
      <LayoutPopup ref="popup" class="popup">
        <slot />
      </LayoutPopup>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import CommonIconButton from '../common/CommonIconButton.vue'
import LayoutPopup from '../layout/LayoutPopup.vue'
import { onClickOutside, useElementBounding, useWindowSize } from '@vueuse/core'

const is_open = ref(false)
const popup = ref(null)
const menu = ref(null)
onClickOutside(popup, () => {
  if ((is_open.value = true)) is_open.value = false
})

const { width, height } = useWindowSize()
const { x, y } = useElementBounding(menu)

function buttonClick(e: Event) {
  is_open.value = true
}
</script>

<style scoped>
.composite-popup-menu {
  position: relative;
  width: fit-content;
}

.popup-wrapper {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  position: absolute;
  z-index: 5;
  opacity: 1;
  top: 16px;
  right: 16px;
}

.popup-wrapper.left {
  right: unset;
  left: 16px;
}

.popup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.close-button {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
}

.closed {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  right: 10px;
  pointer-events: none;
}

.popup-wrapper.bot {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  top: unset;
  bottom: 16px;
}
</style>
