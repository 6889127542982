import type {
  MaterialOwnership,
  APIMaterialOwnershipGETResponse,
  APIPackHistoryGETResponse,
  APIInventoryGETResponse,
  Inventory,
  APIInventoryPOSTResponse,
  APIPackPOSTRequest,
  Pack,
  MissingMaterial,
  APIPackSenderGETResponse,
  APIMissingMaterialsGETResponse,
  APIMissingMaterialsPOSTResponse,
} from 'types/api/material'
import { defineStore } from 'pinia'
import { useGAEAPI } from '@/composable/gae_api'
import { handleErrorNotification } from '@/services/service_api'
import type { ItemListElement } from '@/components/composite/CompositeItemList.vue'
import { useToast } from 'vue-toast-notification'

type MaterialStoreState = {
  inventory?: Inventory
  inventoryHistory: MaterialOwnership[]
  packHistory: APIPackHistoryGETResponse
  isFetching: boolean
  missing_materials: MissingMaterial[]
  kits: {
    id: number;
    title: string;
    date: string;
    customer?: string;
    skills: {
      category: string;
    }[]
  }[]
  to_send: Pack[]
}

export const useMaterialStore = defineStore('material', {
  state: (): MaterialStoreState => ({
    inventory: undefined,
    inventoryHistory: [],
    isFetching: false,
    to_send: [],
    packHistory: [],
    kits: [],
    missing_materials: [],
  }),
  getters: {
    getPackHistory: (state) => state.packHistory,
    getPackHistoryItems: (state): ItemListElement[] => state.packHistory.map(e => ({
      link: `/api${e.link}`,
      text: e.filename,
      date: new Date(e.date).toLocaleDateString('fr-FR', { month: 'long', day: '2-digit', year: 'numeric' })
    })),
    getInventory: (state) => state.inventory,
    getInventoryHistory: (state) => state.inventoryHistory,
    getInventoryHistoryItems: (state): ItemListElement[] => state.inventoryHistory.map(e => ({
      text: `Ajout du matériel "${e.name}"`,
      date: new Date(e.date).toLocaleDateString('fr-FR', { month: 'long', day: '2-digit', year: 'numeric' })
    })),
    getMissingMaterials: (state) => state.missing_materials,
  },
  actions: {
    async fetchSend(user: number) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .get<APIPackSenderGETResponse>(`/api/pack/${user}/send`, {
          headers: {
            "Content-Type": 'application/json',
            Accept: 'application/json'
          }
        })
        .then((response) => {
          this.to_send = response.data.to_send;
          this.kits = response.data.kits;
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
    async fetchInventory(id: number) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .get<APIInventoryGETResponse>(`/api/consultants/${id}/inventory`, {
          headers: {
            Accept: 'application/json'
          }
        })
        .then((response) => {
          this.inventory = response.data
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
    async addPackToInventory(user: number, pack_id: number) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .post<APIInventoryPOSTResponse>(`/api/consultants/${user}/inventory`, { id: pack_id })
        .then((response) => {
          this.inventory = response.data
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
    async removePackFromInventory(user: number, pack_id: number) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .delete(`/api/consultants/${user}/inventory/${pack_id}`, {
          headers: {
            Accept: 'application/json'
          }
        })
        .then(() => {
          const pack_index = this.inventory?.packs.findIndex(e => e.id === pack_id);
          if (pack_index !== undefined) {
            this.inventory?.packs.splice(pack_index, 1);
          }
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
    async fetchInventoryHistory(id: number) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .get<APIMaterialOwnershipGETResponse>(`/api/consultants/${id}/inventory/history`, {
          headers: {
            Accept: 'application/json'
          }
        })
        .then((response) => {
          this.inventoryHistory = response.data
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
    async deletePack(id: number) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .delete(`/api/pack/${id}`, {
          headers: {
            Accept: 'application/json'
          }
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
    async fetchPackHistory(id: number) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .get<APIPackHistoryGETResponse>(`/api/consultants/${id}/history`, {
          headers: {
            Accept: 'application/json'
          }
        })
        .then((response) => {
          this.packHistory = response.data
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
    async sendPacks(user: number, packsToSend: APIPackPOSTRequest) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .post(`/api/pack/${user}/send`, packsToSend, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'file.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
    async fetchMissingMaterials(id: number) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .get<APIMissingMaterialsGETResponse>(`/api/consultants/${id}/missing_materials`,
          {
            headers: {
              Accept: "application/json"
            }
          }
        )
        .then((response) => {
          this.missing_materials = response.data
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
    async addMissingMaterial(user: number, content: string) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .post<APIMissingMaterialsPOSTResponse>(`/api/missing_materials`, { consultant_id: user, content: content })
        .then((response) => {
          this.missing_materials.push(response.data)
          return response.data
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
    async removeMissingMaterial(id: number) {
      const api = useGAEAPI()
      this.isFetching = true
      return api.instance
        .delete(`/api/missing_materials/${id}`, {
          headers: {
            Accept: 'application/json'
          }
        })
        .then(() => {
          const missing_index = this.missing_materials.findIndex(e => e.id === id);
          if (missing_index !== undefined) {
            this.missing_materials.splice(missing_index, 1);
          }
        })
        .catch(handleErrorNotification)
        .finally(() => {
          this.isFetching = false
        })
    },
  }
})
