<template>
  <div class="input-area" :class="{ error: errors && errors.length > 0 }">
    <label :for="id">{{ label }}{{ required ? '*' : '' }} :</label>
    <textarea
      :id="id"
      :rows="rows"
      :placeholder="placeholder"
      :value="modelValue"
      :style="{ resize: resize ? 'vertical' : 'none' }"
      @input="emits('update:modelValue', extractEventValue($event))"
    />
    <div v-if="errors && errors.length > 0" class="input-area__error">
      <AlertCircle :size="16" />
      <p>{{ errors[0].$message }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
import { AlertCircle } from 'lucide-vue-next'

withDefaults(
  defineProps<{
    id: string
    label: string
    rows?: number
    resize?: boolean
    modelValue?: string | number
    placeholder?: string
    required?: boolean
    errors?: ErrorObject[]
  }>(),
  {
    rows: 4
  }
)

const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

function extractEventValue(event: Event) {
  return (event.target as HTMLInputElement).value
}
</script>

<style scoped lang="scss">
textarea {
  resize: none;
}

.input-area {
  display: flex;
  flex-direction: column;

  &__error {
    color: var(--vc-danger);
    font-size: 12px;
    display: flex;
    margin-top: 5px;
    align-items: center;
    gap: 5px;
  }
}

.input-area > label {
  margin-bottom: 15px;
}

.input-area > textarea {
  border: 1px solid rgba(var(--vcrgb-dark), 0.2);
  background-color: transparent;
  border-radius: 5px;
  padding: 20px 20px;
  box-sizing: content-box;
  resize: vertical;
  height: fit-content;
}

.error > textarea {
  border: 2px solid rgba(var(--vcrgb-danger), 0.5);
}
</style>
